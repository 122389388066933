import React from 'react';
import { View, Text, Dimensions, Animated, ScrollView, TextInput } from 'react-native';
import Button from './Button';
import Rules from './Rules';
import GameCardsChoice from './GameCardsChoice';

import { ROOM_BOX_SIZE } from './config';


const WIN_DIM = Dimensions.get('window');
const SCREEN_WIDTH = Math.floor(WIN_DIM.width);
const SCREEN_HEIGHT = Math.floor(WIN_DIM.height);


const DownArrow = () => <View style={{
    width: 0,
    height: 0,
    borderLeftWidth: 10,
    borderLeftStyle: 'solid',
    borderLeftColor: 'transparent',
    borderRightWidth: 10,
    borderRightStyle: 'solid',
    borderRightColor: 'transparent',
    borderTopWidth: 10,
    borderTopStyle: 'solid',
    borderTopColor: 'white',
}} />;

const UpArrow = () => <View style={{
    width: 0,
    height: 0,
    borderLeftWidth: 10,
    borderLeftStyle: 'solid',
    borderLeftColor: 'transparent',
    borderRightWidth: 10,
    borderRightStyle: 'solid',
    borderRightColor: 'transparent',
    borderBottomWidth: 10,
    borderBottomStyle: 'solid',
    borderBottomColor: 'white',
}} />;

const LeftArrow = () => <View style={{
    width: 0,
    height: 0,
    borderTopWidth: 10,
    borderTopStyle: 'solid',
    borderTopColor: 'transparent',
    borderBottomWidth: 10,
    borderBottomStyle: 'solid',
    borderBottomColor: 'transparent',
    borderRightWidth: 10,
    borderRightStyle: 'solid',
    borderRightColor: 'white',
}} />;

const RightArrow = () => <View style={{
    width: 0,
    height: 0,
    borderTopWidth: 10,
    borderTopStyle: 'solid',
    borderTopColor: 'transparent',
    borderBottomWidth: 10,
    borderBottomStyle: 'solid',
    borderBottomColor: 'transparent',
    borderLeftWidth: 10,
    borderLeftStyle: 'solid',
    borderLeftColor: 'white',
}} />;


const TOOLTIP_HORIZONTAL_WIDTH = 240;

const TOOLTIP_VERTICAL_WIDTH = 120;
const TOOLTIP_VERTICAL_HEIGHT = 50;

const MODAL_HEIGHT = 150;
const MODAL_WIDTH = 240;


class SettingsModal extends React.PureComponent {

    setSettingsCardsAR = () => this.props.setSettings({ cards: "AR" });
    setSettingsCardsFR = () => this.props.setSettings({ cards: "FR" });

    onUsernameChange = (e) => this.props.onUsernameChange(e.target.value);
    onUsernameChangeText = (t) => this.props.onUsernameChange(t);

    render() {
        const { username, onHide, settings, hideUsernameInput } = this.props;
        return (
            <View style={{ padding: 10, backgroundColor: "rgb(83, 64, 47)", alignItems: "center", justifyContent: "center", borderColor: "rgb(168, 155, 120)", borderWidth: 2 }}>
                <Text style={{ fontSize: 18, fontWeight: "bold", color: "white", marginBottom: 15 }}>Paramètres</Text>
                {(!hideUsernameInput) ?
                    <View style={{ alignItems: "center", justifyContent: "center" }}>
                        <Text style={{ marginTop: 10, color: "white", marginBottom: 5 }}>Nom d'utilisateur</Text>
                        <TextInput
                            style={{ height: 50, padding: 10, borderColor: "white", borderWidth: 1, color: "white", width: ROOM_BOX_SIZE - 20 }}
                            placeholderTextColor="rgb(168, 155, 120)"
                            onChange={this.onUsernameChange}
                            onChangeText={this.onUsernameChangeText}
                            value={username}
                            placeholder="Entrez votre nom" maxLength={12} />
                    </View> : null}
                <GameCardsChoice setSettingsCardsAR={this.setSettingsCardsAR} setSettingsCardsFR={this.setSettingsCardsFR} settings={settings} />
                <Button style={{
                    width: ROOM_BOX_SIZE - 20,
                    marginTop: 15,
                    height: 50,
                    backgroundColor: "rgb(168, 155, 120)",
                    borderWidth: 1,
                    borderColor: 'white',
                }} onPress={onHide} title={"enregistrer"} />
            </View>
        );
    }
}


class RulesModal extends React.PureComponent {
    render() {
        const { onHide, settings } = this.props;
        let width = SCREEN_WIDTH - 40, height = SCREEN_HEIGHT - 100;
        let padding = 10;

        return (
            <View style={{ backgroundColor: "rgb(83, 64, 47)", width: width, height: height, padding: padding, alignItems: "center", justifyContent: "center", position: "absolute", top: SCREEN_HEIGHT / 2 - height / 2, left: SCREEN_WIDTH / 2 - width / 2, zIndex: 999, borderColor: "rgb(168, 155, 120)", borderWidth: 2 }}>
                <View style={{ width: width - 2 * padding, flexDirection: "row", justifyContent: "center", marginBottom: padding }} >
                    <Text style={{ color: "white", fontSize: 26, fontWeight: "bold", flex: 1 }}>Règles du jeu</Text>
                    <Button style={{
                        backgroundColor: 'rgb(168, 155, 120)',
                        borderWidth: 1,
                        width: 40,
                        borderColor: 'white',
                    }} onPress={onHide} icon="ios-close" iconSize={22} />
                </View>
                <ScrollView style={{ flex: 1, width: width - 2 * padding }}>
                    <Rules settings={settings} />
                </ScrollView>
                <Button style={{
                    marginTop: padding,
                    width: "100%",
                    backgroundColor: "rgb(168, 155, 120)",
                    borderWidth: 1,
                    borderColor: 'white',
                }} onPress={onHide} title={"fermer"} />
            </View>
        )
    }
}

class ConfirmationModal extends React.PureComponent {
    render() {
        const { text, onHide, onConfirm, buttonColor, buttonTitle } = this.props;
        let width = MODAL_WIDTH, padding = 5;

        return (
            <View style={{ width: width, backgroundColor: "white", padding: padding, alignItems: "center", justifyContent: "center", zIndex: 999 }}>
                <Text style={{ textAlign: "center", marginBottom: 10 }} >{text}</Text>
                <Button style={{
                    marginTop: 5,
                    width: "100%",
                    backgroundColor: buttonColor || 'rgb(168, 155, 120)',
                    borderColor: 'rgb(168, 155, 120)',
                    borderWidth: 1,
                }} onPress={onConfirm} title={buttonTitle || "oui"} />
                {onHide?<Button style={{
                    marginTop: padding,
                    width: "100%",
                    backgroundColor: 'white',
                    color: 'rgb(168, 155, 120)',
                    borderWidth: 1,
                    borderColor: 'rgb(168, 155, 120)',
                }} onPress={onHide} title="non" />:null}
            </View>
        )
    }
}


class HelpTooltip extends React.PureComponent {

    render() {
        const { text, onHide, position, attachedComponentLayout, error } = this.props;
        const { x, y, height, width } = attachedComponentLayout;

        let coords = { bottom: SCREEN_HEIGHT - y, left: x + (width / 2 - TOOLTIP_HORIZONTAL_WIDTH / 2) };
        let containerSize = { width: TOOLTIP_HORIZONTAL_WIDTH };
        let size = { width: TOOLTIP_HORIZONTAL_WIDTH };
        let arrow = <DownArrow />;
        let flexDirection = "column";
        if (position === "bottom") {
            coords = { top: y + height, left: x + (width / 2 - TOOLTIP_HORIZONTAL_WIDTH / 2) };
            containerSize = { width: TOOLTIP_HORIZONTAL_WIDTH };
            size = { width: TOOLTIP_HORIZONTAL_WIDTH };
            arrow = <UpArrow />;
            flexDirection = "column-reverse";
        } else if (position === "right") {
            coords = { left: x + width, top: y + (height / 2 - TOOLTIP_VERTICAL_HEIGHT / 2) };
            containerSize = { width: TOOLTIP_VERTICAL_WIDTH };
            size = { width: TOOLTIP_VERTICAL_WIDTH };
            arrow = <LeftArrow />;
            flexDirection = "row-reverse";
        } else if (position === "left") {
            coords = { right: SCREEN_WIDTH - x, top: y + (height / 2 - TOOLTIP_VERTICAL_HEIGHT / 2) };
            containerSize = { width: TOOLTIP_VERTICAL_WIDTH };
            size = { width: TOOLTIP_VERTICAL_WIDTH };
            arrow = <RightArrow />;
            flexDirection = "row";
        }

        return (
            <View style={{ flexDirection: flexDirection, alignItems: "center", justifyContent: "center", position: "absolute", ...coords, ...containerSize, zIndex: 999 }}>
                <View style={{ backgroundColor: "white", padding: 5, ...size }}>
                    <Text style={{ textAlignVertical: "center", textAlign: "center", flex: 1, color: error ? "#e24c2c" : null }} >{text}</Text>
                    {onHide ?
                        <Button style={{
                            marginTop: 5,
                            backgroundColor: 'rgb(168, 155, 120)',
                            borderWidth: 1,
                            borderColor: 'rgb(168, 155, 120)',
                        }} onPress={onHide} title={(error) ? "ok" : "j'ai compris"} />
                        : null}
                </View>
                {arrow}
            </View>
        )
    }
}

class HelpTooltipDisplay extends React.Component {
    animV = new Animated.Value(0)
    state = {
        animV: this.animV,
        fading: false,
    }

    componentDidMount() {
        if (this.props.show) this.animV.setValue(1);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.show && this.props.show) this.fadeIn();
        else if (prevProps.show && !this.props.show) this.fadeOut();
    }

    fadeIn = () => {
        this.setState({
            fading: true,
        }, () => {
            Animated.timing(this.state.animV, {
                toValue: 1,
                duration: 250,
            }).start();
        })
    }

    fadeOut = () => {
        Animated.timing(this.state.animV, {
            toValue: 0,
            duration: 250,
        }).start(() => this.setState({ fading: false }));
    }

    render() {
        const { transparent, zIndex, show, text, onHide, position, buttonTitle, buttonColor, attachedComponentLayout, error, isConfirmationModal, onConfirm, isRulesModal, isSettingsModal, setUsername, onUsernameChange, usernameError, hideUsernameInput, username, settings, setSettings } = this.props;
        const { animV, fading } = this.state;

        return (show || fading) ? (
            <Animated.View style={{ width: SCREEN_WIDTH, height: SCREEN_HEIGHT, zIndex: zIndex, backgroundColor: ((transparent)?"rgba(0, 0, 0, 0)":"rgba(0, 0, 0, 0.6)"), opacity: animV, position: "absolute", top: 0, right: 0, bottom: 0, left: 0, justifyContent: "center", alignItems: "center" }}>
                {isConfirmationModal ?
                    <ConfirmationModal text={text} onHide={onHide} buttonTitle={buttonTitle} buttonColor={buttonColor} onConfirm={onConfirm} />
                    :
                    (isSettingsModal ? (
                        <SettingsModal setUsername={setUsername} onUsernameChange={onUsernameChange} hideUsernameInput={hideUsernameInput} usernameError={usernameError} username={username} onHide={onHide} settings={settings} setSettings={setSettings} />
                    ) : (isRulesModal ?
                        <RulesModal onHide={onHide} settings={settings} /> :
                        <HelpTooltip text={text} error={error} onHide={transparent?null:onHide} position={position} attachedComponentLayout={attachedComponentLayout} />))
                }
            </Animated.View>
        ) : null;
    }
}

export default HelpTooltipDisplay;