import _ from 'lodash';

let ai = {
    getMove: (G, ctx, playerIdx) => {
        if (_.random(0, 100) === 100) {
            if (_.random(0, 1)) {
                return {name: "kant", args: []};
            } else {
                return {name: "copy", args: []};
            }
        }

        const hand = G.hands[playerIdx];
        if (hand[0].substr(1) === hand[1].substr(1) && hand[0].substr(1) === hand[2].substr(1) && hand[0].substr(1) === hand[3].substr(1)) return null;

        if (_.random(0, 2) <= 1) {
            return null;
        }

        const table = G.table;
        let occurences = {};
        for (let i = 0; i < 4; i++) {
            const key = hand[i].substr(1);
            occurences[key] = (occurences[key])?(occurences[key]+1):1;
        }

        let cards_to_keep = {}, curr_occ = 0, max_occ_card = hand[0];
        for (let i = 0; i < 4; i++) {
            const key = hand[i].substr(1);
            if (occurences[key] > curr_occ) {
                cards_to_keep = {};
                cards_to_keep[hand[i]] = true;
                curr_occ = occurences[key];
                max_occ_card = hand[i];
            } else if (occurences[key] === curr_occ) {
                cards_to_keep[hand[i]] = true;
            }
        }
        let cards_to_swap = _.filter(hand, c => !cards_to_keep[c]);
        if (cards_to_swap.length === 0) cards_to_swap = hand;

        for (let i = 0; i < 4; i++) {
            const key = table[i].substr(1);
            if (key === max_occ_card.substr(1)) {
                return {name: "swap", args: [cards_to_swap[_.random(0, cards_to_swap.length-1)], table[i]]};
            }
        }

        return null;
    },
};

export default ai;