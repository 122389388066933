import React from 'react';
import { View, Text } from 'react-native';


const Rules = () => {
    return (
        <View>
            <Text style={{ color: "white" }}>KantCopy se joue à 4 joueurs répartis en deux équipes: Nord/Sud contre Est/Ouest.</Text>
            <Text style={{ color: "white", fontSize: 25, marginTop: 15, marginBottom: 10 }}>But du jeu </Text>
            <Text style={{ color: "white" }}>Former un jeu de 4 cartes identiques (du même numéro) et le faire savoir à son coéquipier discrètement, sans se faire repérer par les autres joueurs.</Text>
            <Text style={{ color: "white", fontSize: 25, marginTop: 15, marginBottom: 10 }}>Préparation du jeu</Text>
            <Text style={{ color: "white" }}>Chaque équipe (de 2 joueurs) est donnée un mot de passe secret au début du jeu. Le mot de passe d’une équipe ne doit pas être révélé à l’autre équipe (sinon elle gagnera).</Text>
            <Text style={{ color: "white", fontSize: 25, marginTop: 15, marginBottom: 10 }}>Déroulement de la partie</Text>
            <Text style={{ color: "white" }}>Une manche se joue jusqu’à ce qu’il y ait un "Kant" ou un "Copy"</Text>
            <Text style={{ color: "white", fontSize: 18, marginTop: 10, fontWeight: "bold", marginBottom: 10 }}>Chasse</Text>
            <Text style={{ color: "white" }}>{`Chaque joueur peut échanger une ou plusieurs des cartes de la table avec la ou les siennes.
Attention : Le joueur doit d’abord poser ses cartes avant de ramasser celles de la table. Ainsi, il est interdit d’avoir plus de 4 cartes en main.`}</Text>
            <Text style={{ color: "white", fontSize: 18, marginTop: 10, fontWeight: "bold", marginBottom: 10 }}>Balayage</Text>
            <Text style={{ color: "white" }}>Lorsqu’aucun échange de cartes n’a eu lieu depuis 7 secondes, un balayage de la table est automatiquement fait: les 4 cartes sont retirées du jeu (dans la “poubelle”) et remplacées par 4 nouvelles. Les cartes de la poubelle sont toujours cachées et peuvent réapparaître plus tard dans la partie.</Text>
            <Text style={{ color: "white", fontSize: 18, marginTop: 10, fontWeight: "bold", marginBottom: 10 }}>Kant</Text>
            <Text style={{ color: "white" }}>Lorsqu’un joueur possède 4 cartes identiques, il doit le signaler à son partenaire grâce au mot de passe secret de l’équipe (qu’ils se sont convenus en début de partie).</Text>
            <Text style={{ color: "white" }}>Le partenaire qui aperçoit le signe de son coéquipier doit alors annoncer "KANT !" pour faire gagner 1 point à son équipe.</Text>
            <Text style={{ color: "white" }}>Si le "KANT" est annoncé par erreur, l’équipe adverse gagne 1 point</Text>
            <Text style={{ color: "white", fontSize: 18, marginTop: 10, fontWeight: "bold", marginBottom: 10 }}>Copy</Text>
            <Text style={{ color: "white" }}>Cependant, si l’adversaire annonce un "COPY !" avant que le joueur n’ait eu le temps d’annoncer "KANT", c’est l’adversaire qui marque 1 point ! A condition bien sûr que l’équipe avait bien 4 cartes identiques</Text>
            <Text style={{ color: "white" }}>Si le "COPY" ne fonctionne pas, l’équipe qui se trompe donne 1 point à l’autre équipe</Text>
        </View>
    )
}


export default Rules;