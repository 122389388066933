import _ from 'lodash';

let TYPES = ["c", "o", "b", "e"];
let DECK = [];
for (let t of TYPES) {
    for (let i = 1; i <= 7; i++) DECK.push(`${t}${i.toString().padStart(2, '0')}`);
    for (let i = 10; i <= 12; i++) DECK.push(`${t}${i.toString().padStart(2, '0')}`);
}

TYPES = _.sortBy(TYPES);
DECK = _.sortBy(DECK);

let deckIdx = {};
let i = 0;
for (let card of DECK) {
    deckIdx[card] = i;
    i++;
}
const INVALID_MOVE_CARD_DOESNT_BELONG_TO_PLAYER = "card doesn't belong to player";
const INVALID_MOVE_CARD_DOESNT_BELONG_TO_TABLE = "card is not on the table";

const isValidSwapMove = (G, playerIdx, hand_card, table_card) => {
    const hands = G.hands;
    const table = G.table;
    const hand = hands[playerIdx];
    if (!_.includes(hand, hand_card)) {
        return { valid: false, reason: INVALID_MOVE_CARD_DOESNT_BELONG_TO_PLAYER };
    }
    if (!_.includes(table, table_card)) {
        return { valid: false, reason: INVALID_MOVE_CARD_DOESNT_BELONG_TO_TABLE };
    }

    return { valid: true };
}

const MOVE_TIMEOUT_S = 7;

const Utils = {
    DECK,
    TYPES,
    INVALID_MOVE_CARD_DOESNT_BELONG_TO_PLAYER,
    INVALID_MOVE_CARD_DOESNT_BELONG_TO_TABLE,
    deckIdx,
    MOVE_TIMEOUT_S,
    playerIDtoIdx: (G, playerID) => G.playerIdx[playerID],
    isValidSwapMove: isValidSwapMove,
};

export default Utils;