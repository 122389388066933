import AsyncStorage from '@react-native-async-storage/async-storage';

let storage = {
    setItem: (k, v, cb) => AsyncStorage.setItem(k, (typeof v === "object") ? JSON.stringify(v) : v, cb),
    getItem: (k, cb) => AsyncStorage.getItem(k, cb),
    getAll: (cb) => {
        let r = {};
        AsyncStorage.getItem("username", (err, v) => {
            if (err && cb) return cb(err);
            r.username = v;
            AsyncStorage.getItem("settings", (err, v) => {
                if (err && cb) return cb(err);
                r.settings = v ? JSON.parse(v) : null;
                AsyncStorage.getItem("gameID", (err, v) => {
                    if (err && cb) return cb(err);
                    r.gameID = v;
                    AsyncStorage.getItem("playerID", (err, v) => {
                        if (err && cb) return cb(err);
                        r.playerID = v;
                        AsyncStorage.getItem("playerCredentials", (err, v) => {
                            if (err && cb) return cb(err);
                            r.playerCredentials = v;
                            if (cb) return cb(null, r);
                        });
                    });
                });
            });
        });
    },
    removeItem: (k, cb) => AsyncStorage.removeItem(k, cb),
    removeAll: (cb) => {
        let r = {};
        AsyncStorage.removeItem("settings", (err, v) => {
            if (err && cb) return cb(err);
            r.settings = v ? JSON.parse(v) : null;
            AsyncStorage.removeItem("gameID", (err, v) => {
                if (err && cb) return cb(err);
                r.gameID = v;
                AsyncStorage.removeItem("playerID", (err, v) => {
                    if (err && cb) return cb(err);
                    r.playerID = v;
                    AsyncStorage.removeItem("playerCredentials", (err, v) => {
                        if (err && cb) return cb(err);
                        r.playerCredentials = v;
                        AsyncStorage.removeItem("username", (err, v) => {
                            if (err && cb) return cb(err);
                            r.username = v;
                            if (cb) return cb(null, r);
                        });
                    });
                });
            });
        });
    },
};
// if (LOCAL) {
//     storage = {
//         _m: {},
//         setItem: (k, v, cb) => { storage._m[k] = v; if (cb) cb(null) },
//         getItem: (k, cb) => { if (cb) cb(null, storage._m[k]) },
//         getAll: (cb) => { ; if (cb) cb(null, storage._m) },
//         removeAll: (cb) => { storage._m = {}; if (cb) cb(null, {}) },
//         removeItem: (k, cb) => { storage._m[k] = undefined; if (cb) cb(null) },
//     }
// }

export default storage;