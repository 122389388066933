import React from 'react';
import { View, TouchableWithoutFeedback, ActivityIndicator, Image, Text, AppState, Platform, Keyboard, Dimensions } from 'react-native';
import { AppLoading } from 'expo';
import { activateKeepAwake } from 'expo-keep-awake';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import { Ionicons } from '@expo/vector-icons';
import _ from 'lodash';
import { Client } from 'boardgame.io/react-native';
import { SocketIO, Local } from 'boardgame.io/multiplayer';
import KantCopy from '../core/game.js';

import Button from './Button';
import QuickForm from './QuickForm';
import GameBoard from './Game';
import GameCardsChoice from './GameCardsChoice';
import HelpTooltipDisplay from './HelpTooltip';
import storage from './storage';

import IMGS_SRC from './img';
import { ROOM_BOX_SIZE, DELETE_STORAGE, OFFLINE, SERVER_ADDR } from './config';



const WIN_DIM = Dimensions.get('window');
const SCREEN_WIDTH = Math.floor(WIN_DIM.width);
const SCREEN_HEIGHT = Math.floor(WIN_DIM.height);


let dummyWindow = {
    location: { pathname: "" },
    document: {},
    history: { replaceState: () => { } }
}


let _window = (Platform.OS === "web") ? window : dummyWindow;


const Connecting = () => (
    <View style={{ alignItems: "center", justifyContent: "center" }}><Text style={{ color: "white" }}>Connection...</Text></View>
);

const KantCopyClient = Client({
    game: KantCopy,
    numPlayers: 4,
    board: GameBoard,
    loading: Connecting,
    multiplayer: (OFFLINE) ? Local() : SocketIO({
        server: SERVER_ADDR,
    }),
});

const KantCopyClientLocal = Client({
    game: KantCopy,
    numPlayers: 4,
    board: GameBoard,
    loading: Connecting,
    multiplayer: Local(),
});


const fetchResHandler = (response) => {
    if (response.ok) {
        return Promise.resolve(response.json());
    }
    return Promise.reject({ code: response.status, msg: response.statusText, response: response });
}

const howManyMissingPlayers = (game) => _.filter(game.players, p => !p.name).length;
const isMissingPlayers = (game) => Boolean(howManyMissingPlayers(game));


const Room = ({ game, onJoin, loading }) => {
    const players = _.sortBy(game.players, (p) => p.id);
    return (
        <View key={game.gameID} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderStyle: "solid", borderColor: "rgb(168, 155, 120)", borderWidth: 2, width: ROOM_BOX_SIZE, height: ROOM_BOX_SIZE, maxWidth: ROOM_BOX_SIZE, maxHeight: ROOM_BOX_SIZE, minWidth: ROOM_BOX_SIZE, minHeight: ROOM_BOX_SIZE }} >
            <View style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center", width: Math.floor(ROOM_BOX_SIZE / 3), height: ROOM_BOX_SIZE, maxWidth: Math.floor(ROOM_BOX_SIZE / 3), maxHeight: ROOM_BOX_SIZE, minWidth: Math.floor(ROOM_BOX_SIZE / 3), minHeight: ROOM_BOX_SIZE }} >
                {(players[3].name) ? <Text style={{ color: "white" }}>{players[3].name}</Text> : <Button disabled={loading} onPress={() => onJoin(game.gameID, 3)} title="rejoindre"></Button>}
            </View>
            <View style={{ flex: 1, display: "flex", flexDirection: "column", width: Math.floor(ROOM_BOX_SIZE / 3), height: ROOM_BOX_SIZE, maxWidth: Math.floor(ROOM_BOX_SIZE / 3), maxHeight: ROOM_BOX_SIZE, minWidth: Math.floor(ROOM_BOX_SIZE / 3), minHeight: ROOM_BOX_SIZE }} >
                <View style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center", width: Math.floor(ROOM_BOX_SIZE / 3), height: Math.floor(ROOM_BOX_SIZE / 3), maxWidth: Math.floor(ROOM_BOX_SIZE / 3), maxHeight: Math.floor(ROOM_BOX_SIZE / 3), minWidth: Math.floor(ROOM_BOX_SIZE / 3), minHeight: Math.floor(ROOM_BOX_SIZE / 3) }} >
                    {(players[2].name) ? <Text style={{ color: "white" }}>{players[2].name}</Text> : <Button disabled={loading} onPress={() => onJoin(game.gameID, 2)} title="rejoindre"></Button>}
                </View>
                <View style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center", width: Math.floor(ROOM_BOX_SIZE / 3), height: Math.floor(ROOM_BOX_SIZE / 3), maxWidth: Math.floor(ROOM_BOX_SIZE / 3), maxHeight: Math.floor(ROOM_BOX_SIZE / 3), minWidth: Math.floor(ROOM_BOX_SIZE / 3), minHeight: Math.floor(ROOM_BOX_SIZE / 3) }} >
                    {loading ? <ActivityIndicator color="white" /> : null}
                </View>
                <View style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center", width: Math.floor(ROOM_BOX_SIZE / 3), height: Math.floor(ROOM_BOX_SIZE / 3), maxWidth: Math.floor(ROOM_BOX_SIZE / 3), maxHeight: Math.floor(ROOM_BOX_SIZE / 3), minWidth: Math.floor(ROOM_BOX_SIZE / 3), minHeight: Math.floor(ROOM_BOX_SIZE / 3) }} >
                    {(players[0].name) ? <Text style={{ color: "white" }}>{players[0].name}</Text> : <Button disabled={loading} onPress={() => onJoin(game.gameID, 0)} title="rejoindre"></Button>}
                </View>
            </View>
            <View style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center", width: Math.floor(ROOM_BOX_SIZE / 3), height: ROOM_BOX_SIZE, maxWidth: Math.floor(ROOM_BOX_SIZE / 3), maxHeight: ROOM_BOX_SIZE, minWidth: Math.floor(ROOM_BOX_SIZE / 3), minHeight: ROOM_BOX_SIZE }} >
                {(players[1].name) ? <Text style={{ color: "white" }}>{players[1].name}</Text> : <Button disabled={loading} onPress={() => onJoin(game.gameID, 1)} title="rejoindre"></Button>}
            </View>
        </View>
    );
}

class Lobby extends React.Component {

    state = {
        ctrls: [],
        tooltip: {},
        settings: { cards: "AR" },
        isAppActive: AppState.currentState === "active",
        preloadWebProgress: (Platform.OS === "web") ? 0 : null,
    }

    componentDidMount() {
        activateKeepAwake();
        const load = () => storage.getAll((err, stored) => {
            if (err) return;
            let pathGameID = (_window.location.pathname.split("/").length > 1) ? _window.location.pathname.split("/")[1] : null;
            if (pathGameID && pathGameID !== stored.gameID) {
                stored.gameID = null;
                stored.playerCredentials = null;
                stored.playerID = null;
                storage.removeItem("playerID");
                storage.removeItem("gameID");
                storage.removeItem("playerCredentials");
            }

            this.setState({
                game: (OFFLINE) ? { gameID: "0", players: [{ id: 0, name: "0" }, { id: 1, name: "1" }, { id: 2, name: "2" }, { id: 3, name: "3" }] } : null,
                username: stored.username || "",
                usernameSet: (OFFLINE) ? true : (Boolean(stored.username)),
                loading: (OFFLINE) ? false : true,
                gameID: (OFFLINE) ? "0" : ((stored.gameID) ? stored.gameID : pathGameID),
                playerID: (OFFLINE) ? "0" : stored.playerID,
                playerCredentials: (OFFLINE) ? "0" : stored.playerCredentials,
                settings: stored.settings || { cards: "AR" },
            }, () => {
                if (!OFFLINE) {
                    if (this.state.gameID) {
                        this.fetchGame(this.state.gameID);
                    } else {
                        this.setState({
                            loading: false,
                        })
                    }
                }
            });
        });
        if (DELETE_STORAGE) storage.removeAll(load);
        else load();

        AppState.addEventListener("change", (nextAppState) => {
            this.setState({
                isAppActive: nextAppState === "active",
            })
        })

        if (Platform.OS === "web") {
            this._preLoadImages();
        }
    }

    componentWillUnmount() {
        if (this.timeout) clearInterval(this.timeout);
    }

    handleGameNotFound = (cb) => {
        if (this.timeout) clearTimeout(this.timeout);
        storage.removeItem("gameID", () => storage.removeItem("playerID", () => storage.removeItem("playerCredentials", () => {
            _window.history.replaceState(null, _window.document.title, "/");
            this.setState({
                gameID: null,
                game: null,
                playerID: null,
                playerCredentials: null,
                loading: false,
                inRoomLoading: false,
            }, () => { if (cb) cb() });
        })));
    }

    fetchGame = (gameID) => {
        this.setState({
            loading: true,
        }, () => {
            fetch(`${SERVER_ADDR}/games/kantcopy/${gameID}`)
                .then(fetchResHandler)
                .then((game) => {
                    game.gameID = game.matchID;
                    _window.history.replaceState(null, _window.document.title, gameID);
                    storage.setItem("gameID", game.gameID);
                    this.setState({
                        loading: false,
                        game: game,
                        error: null,
                    }, () => {
                        if (isMissingPlayers(game)) {
                            this.timeout = setTimeout(this.updateGameInBackground, 1500);
                        }
                    });
                })
                .catch((error) => {
                    if (!error) return;
                    if (error.code === 404) return this.handleGameNotFound(() => {
                        this.setState({
                            loading: false,
                        });
                    });
                    this.setState({
                        loading: false,
                        error: `Erreur ${error.code || "de connexion"}`,
                    });
                });
        });
    }

    updateGameInBackground = () => {
        if (!this.state.gameID || this.state.gameID === "local") return;

        fetch(`${SERVER_ADDR}/games/kantcopy/${this.state.gameID}`)
            .then(fetchResHandler)
            .then((game) => {
                game.gameID = game.matchID;
                this.setState({
                    game: game,
                }, () => {
                    if (this.timeout) clearTimeout(this.timeout);
                    if (isMissingPlayers(game)) {
                        this.timeout = setTimeout(this.updateGameInBackground, 1500);
                    }
                });
            })
            .catch((error) => {
                if (!error) return;
                if (error.code === 404) {
                    this.handleGameNotFound();
                    return;
                }
                if (this.timeout) clearTimeout(this.timeout);
                this.timeout = setTimeout(this.updateGameInBackground, 1500);
            });
    }

    onCreateLocal = () => {
        this.setState({
            game: {
                gameID: "local",
                players: [{ id: 0, name: this.state.username }, { id: 1, name: "Joueur 1" }, { id: 2, name: "Joueur 2" }, { id: 3, name: "Joueur 3" }],
            },
            gameID: "local",
            playerCredentials: "local",
            playerID: "0",
        })
    }

    create = (cb, fcb) => {
        fetch(`${SERVER_ADDR}/games/kantcopy/create`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                numPlayers: 4,
            })
        })
            .then(fetchResHandler)
            .then(
                (game) => { if (cb) cb(game); }
            ).catch((error) => {
                if (!error) return;
                if (fcb) fcb(error);
            })
    }

    onCreate = () => {
        this.setState({
            loading: true,
        }, () => {
            const success = (game) => {
                storage.removeItem("playerID");
                storage.removeItem("playerCredentials");
                storage.setItem("gameID", game.matchID);
                this.setState({
                    gameID: game.matchID,
                    playerID: null,
                    playerCredentials: null,
                    error: null,
                }, () => this.fetchGame(game.matchID));
            };
            const failure = (error) => {
                if (!error) return;
                this.setState({
                    loading: false,
                    error: `impossible de créer une nouvelle partie`,
                });
            }
            if (this.state.playerID && this.state.playerCredentials) {
                this.leave(this.state.playerID, this.state.playerCredentials, () => {
                    this.create(success, failure);
                }, failure);
                return;
            }
            this.create(success, failure);
        });
    }

    join = (gameID, seat, cb) => {
        fetch(`${SERVER_ADDR}/games/kantcopy/${gameID}/join`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                playerID: `${seat}`,
                playerName: this.state.username,
            }),
        })
            .then(fetchResHandler)
            .then((res) => {
                if (!res.playerCredentials) {
                    return;
                }
                storage.setItem("playerID", `${seat}`);
                storage.setItem("gameID", gameID);
                storage.setItem("playerCredentials", res.playerCredentials);

                let game = _.cloneDeep(this.state.game);
                game.players[seat].name = this.state.username;
                this.setState({
                    game: game,
                    gameID: gameID,
                    playerID: `${seat}`,
                    playerCredentials: res.playerCredentials,
                    error: null,
                }, () => { if (cb) cb() });
            })
            .catch((error) => {
                if (!error) return;
                if (error.code === 404) {
                    return this.handleGameNotFound();
                }
                this.setState({
                    inRoomLoading: false,
                    error: `impossible de rejoindre la table`,
                });
            })
    }

    onJoin = (gameID, seat) => {
        const game = this.state.game;
        if (!game) return;

        this.setState({
            inRoomLoading: true,
        }, () => {
            const prevPlayerCredentials = this.state.playerCredentials;
            const prevPlayerID = this.state.playerID;

            if (prevPlayerCredentials && prevPlayerID) {
                const missingPlayers = howManyMissingPlayers(game);
                const prevSeat = parseInt(prevPlayerID, 10);
                if (missingPlayers === 1) {
                    let _game = _.cloneDeep(game);
                    _game.players[prevSeat].name = null;
                    this.leave(prevPlayerID, prevPlayerCredentials, () => {
                        this.setState({
                            game: _game,
                        }, () => this.join(gameID, seat, () => {
                            this.setState({
                                inRoomLoading: false,
                            });
                        }));
                    }, () => this.setState({
                        inRoomLoading: false,
                    }));
                } else {
                    this.join(gameID, seat, () => {
                        let _game = _.cloneDeep(this.state.game);
                        _game.players[prevSeat].name = null;
                        this.leave(prevPlayerID, prevPlayerCredentials, () => this.setState({
                            game: _game,
                            inRoomLoading: false,
                        }), () => this.setState({
                            inRoomLoading: false,
                        }));
                    });
                }
                return;
            }
            this.join(gameID, seat, () => this.setState({
                inRoomLoading: false,
            }));
        });
    }

    leave = (playerID, playerCredentials, cb, fcb) => {
        if (this.state.gameID === "local") return cb();
        fetch(`${SERVER_ADDR}/games/kantcopy/${this.state.gameID}/leave`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                playerID: playerID,
                credentials: playerCredentials,
            }),
        })
            .then(fetchResHandler)
            .then(() => {
                if (cb) cb();
            })
            .catch((error) => {
                if (!error) return;
                if (fcb) fcb(error);
            });
    }

    onLeave = () => {
        if (this.state.gameID === "local") return this.onLeaveRoom();
        if (!this.state.gameID || !this.state.playerCredentials) return;

        this.setState({
            loading: true,
        }, () => this.leave(this.state.playerID, this.state.playerCredentials, () => {
            storage.removeItem("playerID");
            storage.removeItem("playerCredentials");
            this.setState({
                loading: false,
                playerID: null,
                playerCredentials: null,
            }, this.updateGameInBackground);
        }, (error) => {
            if (!error) return;
            if (error.code === 404) {
                this.handleGameNotFound(() => this.setState({
                    loading: false,
                }));
            }
        }));
    };

    onLeaveRoom = () => {
        if (this.timeout) clearTimeout(this.timeout);
        if (!this.state.playerCredentials) return this.setState({
            loading: true,
        }, () => this.handleGameNotFound());

        this.setState({
            loading: true,
        }, () => this.leave(this.state.playerID, this.state.playerCredentials, () => {
            storage.removeItem("gameID");
            storage.removeItem("playerID");
            storage.removeItem("playerCredentials");
            _window.history.replaceState(null, _window.document.title, "/");
            this.setState({
                gameID: null,
                game: null,
                playerID: null,
                playerCredentials: null,
                loading: false,
            });
        }, (error) => {
            if (!error) return;
            if (error.code === 404) {
                this.handleGameNotFound(() => this.setState({
                    loading: false,
                }));
            }
        }));
    }

    onUsernameChange = (t) => this.setState({ username: t });
    onGameIDTypeChange = (t) => this.setState({ gameID: t });

    setUsernameModal = () => this.setState({
        tooltip: {
            ...this.state.tooltip,
            show: false,
        }
    }, this.setUsername)

    setUsername = () => {
        if (!this.state.username) return;
        const inGame = this.state.playerCredentials && this.state.playerID;
        let game = null;
        if (inGame && this.state.game) {
            game = _.cloneDeep(this.state.game);
            game.players[parseInt(this.state.playerID, 10)].name = this.state.username;
        }

        this.setState({
            usernameSet: true,
            usernameError: null,
            error: null,
            ...((inGame) ? { game: game, inRoomLoading: true } : {}),
        }, () => {
            storage.setItem("username", this.state.username);
            if (inGame) {
                fetch(`${SERVER_ADDR}/games/kantcopy/${this.state.gameID}/rename`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        playerID: this.state.playerID,
                        credentials: this.state.playerCredentials,
                        newName: this.state.username,
                    }),
                })
                    .then(fetchResHandler)
                    .then(() => this.setState({
                        inRoomLoading: false,
                    }))
                    .catch((error) => {
                        if (!error) return;
                        if (error.code === 404) return this.handleGameNotFound();
                        this.setState({
                            inRoomLoading: false,
                            usernameError: error.msg || true,
                            error: `impossible de changer de nom`
                        });
                    });
            }
        });
    }

    setSettings = (update) => {
        this.setState({
            settings: { ...this.state.settings, ...update },
        }, () => {
            storage.setItem("settings", this.state.settings);
        })
    }

    setGameID = () => {
        this.fetchGame(this.state.gameID);
    }

    onReady = () => this.setState({ isReady: true })


    _cacheResourcesAsync = () => {
        const cacheImages = _.map(_.values(IMGS_SRC), image => {
            return Asset.fromModule(image).downloadAsync();
        });
        const cacheFonts = _.map([Ionicons.font], font => Font.loadAsync(font));
        return Promise.all([...cacheImages, ...cacheFonts]);
    }

    _preLoadImages = () => {
        const images = _.values(IMGS_SRC);
        const cacheFonts = _.map([Ionicons.font], font => Font.loadAsync(font));
        let loaded = 0;
        const progress = () => {
            const pgr = ++loaded;
            const done = pgr === (images.length + cacheFonts.length);
            this.setState({
                preloadWebProgress: Math.ceil(pgr * 100 / (images.length + cacheFonts.length)),
                isReady: done,
            })
        }
        _.map(images, image => {
            return Asset.fromModule(image).downloadAsync().then(progress);
        });
        _.map(cacheFonts, font => font.then(progress));
    }

    askedForHelp = () => {
        this.setState({
            tooltip: {
                show: true,
                isRulesModal: true,
            }
        })
    }

    showQuitConfirmationModal = () => {
        this.setState({
            tooltip: {
                show: true,
                text: `Voulez-vous vraiment quitter la partie?`,
                fullScreen: false,
                isConfirmationModal: true,
                onConfirm: this.confirmLeaveRoom,
            }
        })
    };

    confirmLeaveRoom = () => {
        this.setState({
            tooltip: {
                ...this.state.tooltip,
                show: false,
            }
        }, this.onLeaveRoom)
    }

    showSettingsModal = () => {
        this.setState({
            tooltip: {
                show: true,
                isSettingsModal: true,
            }
        })
    }

    onHideTooltip = () => {
        this.setState({
            tooltip: {
                ...this.state.tooltip,
                show: false,
            }
        })
    }

    setSettingsCardsAR = () => this.setSettings({ cards: "AR" });
    setSettingsCardsFR = () => this.setSettings({ cards: "FR" });

    render() {
        if (!this.state.isReady) {
            if (Platform.OS !== "web") {
                return (
                    <AppLoading
                        startAsync={this._cacheResourcesAsync}
                        onFinish={this.onReady}
                        onError={console.warn}
                    />
                );
            } else {
                return (
                    <View style={{ width: "100%", height: "100%", alignItems: "center", justifyContent: "center", backgroundColor: "rgb(83, 64, 47)" }} >
                        <Text style={{ color: "white" }}>Chargement</Text>
                        <Text style={{ color: "white" }}>{this.state.preloadWebProgress} %</Text>
                    </View>
                )
            }
        }

        const { loading, playerID, gameID, playerCredentials, game, username, usernameSet, error, usernameError, inRoomLoading, isAppActive, tooltip } = this.state;

        let mainComponent = null, inGame = false;
        const isMissing = game && isMissingPlayers(game);

        const usernameForm = (
            <QuickForm
                onSubmit={this.setUsername}
                onChange={this.onUsernameChange}
                error={usernameError}
                BtnTitle={(!usernameSet) ? "ok" : "changer"}
                value={username}
                placeholder="Entrez votre nom"
                maxLength={12} />
        );

        const gameIDForm = (
            <QuickForm
                onSubmit={this.setGameID}
                onChange={this.onGameIDTypeChange}
                error={error}
                BtnIcon="ios-log-in"
                BtnIconSize={24}
                value={gameID}
                placeholder="Entrez le code d'une partie"
                maxLength={12} />
        )

        const createGameButton = (
            <Button style={{ height: 50, width: ROOM_BOX_SIZE }} onPress={this.onCreate} title="Nouvelle partie en ligne" icon="people" iconSize={34}></Button>
        );

        const playWithAIGameButton = (
            <Button style={{ height: 50, width: ROOM_BOX_SIZE, marginTop: 15 }} onPress={this.onCreateLocal} title="Nouvelle partie locale" icon="person" iconSize={34}></Button>
        );

        const quitGameButton = (
            <Button style={{ height: 50, width: ROOM_BOX_SIZE }} onPress={this.showQuitConfirmationModal} title="Quitter la table" icon="ios-log-out" iconSize={34}></Button>
        );

        const showRulesButton = (
            <Button style={{ height: 50, width: ROOM_BOX_SIZE, marginTop: 15, backgroundColor: "rgb(83, 64, 47)" }} onPress={this.askedForHelp} title="Règles du jeu" icon="ios-help-circle-outline" iconSize={34}></Button>
        );

        const showSettingsButton = (
            <Button style={{ height: 50, width: ROOM_BOX_SIZE, marginTop: 15, backgroundColor: "rgb(83, 64, 47)" }} onPress={this.showSettingsModal} title="Paramètres" icon="ios-cog" iconSize={34}></Button>
        );

        const credits = (
            <View style={{ alignItems: "center", marginTop: 25 }}>
                <Text style={{ color: "white" }}>Made with ♡ in Fez</Text>
                <Image style={{ width: 30, height: 30 }} source={IMGS_SRC['fezIMG']} ></Image>
            </View>
        );

        if (!usernameSet) {
            mainComponent = (
                <View style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: ROOM_BOX_SIZE, position: "absolute", top: 150 }}>
                    {usernameForm}
                    <GameCardsChoice setSettingsCardsAR={this.setSettingsCardsAR} setSettingsCardsFR={this.setSettingsCardsFR} settings={this.state.settings} />
                    {credits}
                </View>
            );
        } else if (loading) {
            mainComponent = (
                <View style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: ROOM_BOX_SIZE, position: "absolute", left: SCREEN_WIDTH / 2 - ROOM_BOX_SIZE / 2, top: SCREEN_HEIGHT / 2 - (42 + 20 + 50 + 15 + 50 + 15 + 50 + 15 + 50 + 15 + 25 + 42 + 30 + 15) / 2 }}>
                    <Image style={{ width: 42, height: 42 }} source={IMGS_SRC['headerIMG']} />
                    <View style={{ height: 345, justifyContent: "center", alignItems: "center" }}>
                        <ActivityIndicator color="white" />
                    </View>
                    {credits}
                </View>
            );
        } else if (gameID && playerID && playerCredentials && game && !isMissing) {
            mainComponent = (gameID !== "local") ? (
                isAppActive ? (
                    (<KantCopyClient
                        playerID={`${playerID}`}
                        gameID={`${gameID}`}
                        credentials={playerCredentials}
                        onLeave={this.onLeave}
                        showSettingsModal={this.showSettingsModal}
                        settings={this.state.settings}
                        players={game.players}
                        setSettings={this.setSettings} />)
                ) : <Connecting />
            ) : (
                    <>
                    <KantCopyClientLocal
                        playerID={`${playerID}`}
                        gameID={`${gameID}`}
                        credentials={playerCredentials}
                        onLeave={this.onLeave}
                        showSettingsModal={this.showSettingsModal}
                        username={username}
                        isLocal={gameID === "local"}
                        settings={this.state.settings}
                        players={game.players}
                        setSettings={this.setSettings} />
                    <KantCopyClientLocal
                        isAI={true} playerID={'1'} />
                    <KantCopyClientLocal
                        isAI={true} playerID={'2'} />
                    <KantCopyClientLocal
                        isAI={true} playerID={'3'} />
                    </>
                );
            inGame = true;
        } else if (game) {
            let missing = _.filter(game.players, p => !p.name).length;

            mainComponent = (
                <View style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: ROOM_BOX_SIZE }} >
                    <Image style={{ width: 42, height: 42, marginBottom: 15 }} source={IMGS_SRC['headerIMG']} />
                    <View style={{ marginBottom: 10, display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Text style={{ color: "white" }}>Code pour rejoindre la table</Text>
                        <Text style={{ color: "white", padding: 5, fontWeight: "bold" }} selectable>{game.gameID}</Text>
                    </View>
                    <View style={{ marginBottom: 10, display: "flex", justifyContent: "center", alignItems: "center" }} >
                        {missing ? (
                            <Text style={{ color: "white" }}>En attente d{(missing > 1) ? "e" : "'un"} {(missing > 1) ? `${missing} ` : ""}{(missing > 1) ? "autres" : "dernier"} joueur{(missing > 1) ? "s" : ""}...</Text>
                        ) : (
                                <Text style={{ color: "white" }}>table complète</Text>
                            )}
                    </View>
                    <Room game={game} onJoin={this.onJoin} loading={loading} loading={inRoomLoading} />
                    <View style={{ marginTop: 10, width: ROOM_BOX_SIZE }}>
                        {quitGameButton}
                    </View>
                </View>
            );
        } else {
            mainComponent = (
                <View style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: ROOM_BOX_SIZE, position: "absolute", left: SCREEN_WIDTH / 2 - ROOM_BOX_SIZE / 2, top: SCREEN_HEIGHT / 2 - (42 + 20 + 50 + 15 + 50 + 15 + 50 + 15 + 50 + 15 + 25 + 42 + 30 + 15) / 2 }}>
                    <Image style={{ width: 42, height: 42 }} source={IMGS_SRC['headerIMG']} />
                    {gameIDForm}
                    {createGameButton}
                    {playWithAIGameButton}
                    <View style={{ height: 15 }} ></View>
                    {showRulesButton}
                    {showSettingsButton}
                    {credits}
                </View>
            );
        }

        const helptoolDisplay = <HelpTooltipDisplay
            show={tooltip.show}
            text={tooltip.text}
            error={tooltip.error}
            onHide={this.onHideTooltip}
            isConfirmationModal={tooltip.isConfirmationModal}
            onConfirm={tooltip.onConfirm}
            isRulesModal={tooltip.isRulesModal}
            isSettingsModal={tooltip.isSettingsModal}
            settings={this.state.settings}
            setSettings={this.setSettings}
            setUsername={this.setUsernameModal}
            onUsernameChange={this.onUsernameChange}
            hideUsernameInput={inGame}
            usernameError={usernameError}
            username={username} />;

        const main = (
            <View style={{ width: "100%", height: "100%", alignItems: "center", justifyContent: "center", backgroundColor: "rgb(83, 64, 47)" }} >
                {(Platform.OS !== "web" && !inGame) ? (
                    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                        <View style={{ width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
                            {mainComponent}
                        </View>
                    </TouchableWithoutFeedback>
                ) : mainComponent}
                {helptoolDisplay}
            </View>
        );

        return main;
    }
}

export default Lobby;
