export default [
    { "fr": "14-Juillet" },
    { "fr": "1er-Avril" },
    { "fr": "Abeille" },
    { "fr": "Abricot" },
    { "fr": "Acrobate" },
    { "fr": "Acteur" },
    { "fr": "Aéroport" },
    { "fr": "Agneau" },
    { "fr": "Agriculteur" },
    { "fr": "Aigle" },
    { "fr": "Aiguille" },
    { "fr": "Ail" },
    { "fr": "Aile" },
    { "fr": "Air" },
    { "fr": "Albert Einstein" },
    { "fr": "Alice au pays des merveilles" },
    { "fr": "Allumette" },
    { "fr": "Amazone" },
    { "fr": "Ambulance" },
    { "fr": "Ampoule" },
    { "fr": "Ananas" },
    { "fr": "Âne" },
    { "fr": "Animal" },
    { "fr": "Anniversaire" },
    { "fr": "Anubis" },
    { "fr": "Aphrodite" },
    { "fr": "Apiculteur" },
    { "fr": "Apollon" },
    { "fr": "Appareil dentaire" },
    { "fr": "Appareil photographique" },
    { "fr": "Aquarium" },
    { "fr": "Araignée" },
    { "fr": "Arbre" },
    { "fr": "Arc-en-ciel" },
    { "fr": "Archéologue" },
    { "fr": "Architecte" },
    { "fr": "Arès" },
    { "fr": "Arête" },
    { "fr": "Argent" },
    { "fr": "Arrosoir" },
    { "fr": "Artémis" },
    { "fr": "Ascenseur" },
    { "fr": "Asperge" },
    { "fr": "Aspirateur" },
    { "fr": "Aspirine" },
    { "fr": "Assiette" },
    { "fr": "Astérix" },
    { "fr": "Astronaute" },
    { "fr": "Astronomie" },
    { "fr": "Athéna" },
    { "fr": "Atterrir" },
    { "fr": "Au clair de la lune" },
    { "fr": "Aubergine" },
    { "fr": "Automne" },
    { "fr": "Autruche" },
    { "fr": "Avion" },
    { "fr": "Bague" },
    { "fr": "Baguette" },
    { "fr": "Bâiller" },
    { "fr": "Balai" },
    { "fr": "Balançoire" },
    { "fr": "Baleine" },
    { "fr": "Baleine bleue" },
    { "fr": "Balle" },
    { "fr": "Ballon" },
    { "fr": "Banane" },
    { "fr": "Banc" },
    { "fr": "Bande dessinée" },
    { "fr": "Barbe" },
    { "fr": "Barque" },
    { "fr": "Barrette" },
    { "fr": "Basket-ball" },
    { "fr": "Bateau" },
    { "fr": "Bâton" },
    { "fr": "Bavoir" },
    { "fr": "Bec" },
    { "fr": "Béquille" },
    { "fr": "Berger" },
    { "fr": "Berger allemand" },
    { "fr": "Bermuda" },
    { "fr": "Beurre" },
    { "fr": "Biberon" },
    { "fr": "Bibliothèque" },
    { "fr": "Bifteck" },
    { "fr": "Big Ben" },
    { "fr": "Bijou" },
    { "fr": "Bijoutier" },
    { "fr": "Bille" },
    { "fr": "Billet" },
    { "fr": "Biologie" },
    { "fr": "Biscuit" },
    { "fr": "Bison" },
    { "fr": "Blé" },
    { "fr": "Bœuf" },
    { "fr": "Boisson" },
    { "fr": "Bol" },
    { "fr": "Bonbon" },
    { "fr": "Bonnet" },
    { "fr": "Boomerang" },
    { "fr": "Botte" },
    { "fr": "Bouche" },
    { "fr": "Boucher" },
    { "fr": "Boucherie" },
    { "fr": "Bouchon" },
    { "fr": "Boue" },
    { "fr": "Bouée" },
    { "fr": "Boulanger" },
    { "fr": "Boulangerie" },
    { "fr": "Bouteille" },
    { "fr": "Bouton" },
    { "fr": "Bracelet" },
    { "fr": "Bras" },
    { "fr": "Bretelle" },
    { "fr": "Brocoli" },
    { "fr": "Brosse à dents" },
    { "fr": "Brouette" },
    { "fr": "Bugs Bunny" },
    { "fr": "Bureau" },
    { "fr": "Bus" },
    { "fr": "Cabane" },
    { "fr": "Cadeau" },
    { "fr": "Cadenas" },
    { "fr": "Café" },
    { "fr": "Cafetière" },
    { "fr": "Cage" },
    { "fr": "Cagoule" },
    { "fr": "Caillou" },
    { "fr": "Calculette" },
    { "fr": "Calendrier" },
    { "fr": "Caméléon" },
    { "fr": "Caméra" },
    { "fr": "Camion" },
    { "fr": "Camping-car" },
    { "fr": "Canapé" },
    { "fr": "Canard" },
    { "fr": "Caniche" },
    { "fr": "Canne à pêche" },
    { "fr": "Car" },
    { "fr": "Caravane" },
    { "fr": "Carnaval" },
    { "fr": "Carotte" },
    { "fr": "Cartable" },
    { "fr": "Casque" },
    { "fr": "Casquette" },
    { "fr": "Casserole" },
    { "fr": "Castor" },
    { "fr": "Ceinture" },
    { "fr": "Cendrillon" },
    { "fr": "Centaure" },
    { "fr": "Cerbère" },
    { "fr": "Cerceau" },
    { "fr": "Céréale" },
    { "fr": "Cerf" },
    { "fr": "Cerf-volant" },
    { "fr": "Cerise" },
    { "fr": "Cerveau" },
    { "fr": "Chaise" },
    { "fr": "Chameau" },
    { "fr": "Champignon" },
    { "fr": "Chandeleur" },
    { "fr": "Chanter" },
    { "fr": "Chanteur" },
    { "fr": "Chapeau" },
    { "fr": "Chariot" },
    { "fr": "Charlemagne" },
    { "fr": "Charpentier" },
    { "fr": "Chasseur" },
    { "fr": "Chat" },
    { "fr": "Châtaigne" },
    { "fr": "Château" },
    { "fr": "Chaton" },
    { "fr": "Chaussette" },
    { "fr": "Chaussettes" },
    { "fr": "Chausson" },
    { "fr": "Chaussure" },
    { "fr": "Chauve-souris" },
    { "fr": "Chemise" },
    { "fr": "Chenille" },
    { "fr": "Chercheur d'or" },
    { "fr": "Cheval" },
    { "fr": "Cheval de Troie" },
    { "fr": "Chevaliers de la Table ronde" },
    { "fr": "Cheveu" },
    { "fr": "Cheville" },
    { "fr": "Chèvre" },
    { "fr": "Chien" },
    { "fr": "Chiffon" },
    { "fr": "Chiot" },
    { "fr": "Chips" },
    { "fr": "Chirurgien" },
    { "fr": "Chocolat" },
    { "fr": "Chou" },
    { "fr": "Chouette" },
    { "fr": "Christophe Colomb" },
    { "fr": "Chuchoter" },
    { "fr": "Cigogne" },
    { "fr": "Cil" },
    { "fr": "Ciseaux" },
    { "fr": "Citron" },
    { "fr": "Citrouille" },
    { "fr": "Clé" },
    { "fr": "Clémentine" },
    { "fr": "Cléopâtre" },
    { "fr": "Clignotant" },
    { "fr": "Clou" },
    { "fr": "Clown" },
    { "fr": "Coccinelle" },
    { "fr": "Cochon" },
    { "fr": "Cocotte" },
    { "fr": "Cœur" },
    { "fr": "Coffre" },
    { "fr": "Coiffeur" },
    { "fr": "Collant" },
    { "fr": "Colle" },
    { "fr": "Collier" },
    { "fr": "Colorier" },
    { "fr": "Concombre" },
    { "fr": "Conduire" },
    { "fr": "Confiture" },
    { "fr": "Console de jeu" },
    { "fr": "Coq" },
    { "fr": "Coquelicot" },
    { "fr": "Coquillage" },
    { "fr": "Corbeau" },
    { "fr": "Corbeille" },
    { "fr": "Corde" },
    { "fr": "Cou" },
    { "fr": "Coude" },
    { "fr": "Coudre" },
    { "fr": "Couette" },
    { "fr": "Couronne" },
    { "fr": "Coussin" },
    { "fr": "Couteau" },
    { "fr": "Couver" },
    { "fr": "Couvercle" },
    { "fr": "Couverture" },
    { "fr": "Cow-boy" },
    { "fr": "Crabe" },
    { "fr": "Cracher" },
    { "fr": "Craie" },
    { "fr": "Crapaud" },
    { "fr": "Cravate" },
    { "fr": "Crayon" },
    { "fr": "Crèche" },
    { "fr": "Crème solaire" },
    { "fr": "Crêpe" },
    { "fr": "Creuser" },
    { "fr": "Crevette" },
    { "fr": "Crochet" },
    { "fr": "Crocodile" },
    { "fr": "Croissant" },
    { "fr": "Cronos" },
    { "fr": "Croûte" },
    { "fr": "Cube" },
    { "fr": "Cuillère" },
    { "fr": "Cuisinier" },
    { "fr": "Cuisse" },
    { "fr": "Culotte" },
    { "fr": "Cyclisme" },
    { "fr": "Cyclope" },
    { "fr": "Cygne" },
    { "fr": "Danser" },
    { "fr": "Dauphin" },
    { "fr": "Dé" },
    { "fr": "Déchirer" },
    { "fr": "Déguisement" },
    { "fr": "Dent" },
    { "fr": "Dentifrice" },
    { "fr": "Dentiste" },
    { "fr": "Dessert" },
    { "fr": "Diamant" },
    { "fr": "Diane" },
    { "fr": "Dictionnaire" },
    { "fr": "Dindon" },
    { "fr": "Dînette" },
    { "fr": "Dinosaure" },
    { "fr": "Dionysos" },
    { "fr": "Doigt" },
    { "fr": "Domino" },
    { "fr": "Dompteur" },
    { "fr": "Donald Duck" },
    { "fr": "Dormir" },
    { "fr": "Dos" },
    { "fr": "Dragon" },
    { "fr": "Drap" },
    { "fr": "Dromadaire" },
    { "fr": "Eau" },
    { "fr": "Écharpe" },
    { "fr": "Échasse" },
    { "fr": "Échecs" },
    { "fr": "Échelle" },
    { "fr": "École" },
    { "fr": "École maternelle" },
    { "fr": "Écran" },
    { "fr": "Écrire" },
    { "fr": "Écureuil" },
    { "fr": "Égypte" },
    { "fr": "Élastique" },
    { "fr": "Éléphant" },
    { "fr": "Elfe" },
    { "fr": "Empire State Building" },
    { "fr": "Endive" },
    { "fr": "Entonnoir" },
    { "fr": "Enveloppe" },
    { "fr": "Épaule" },
    { "fr": "Épingle" },
    { "fr": "Épiphanie" },
    { "fr": "Éponge" },
    { "fr": "Escalier mécanique" },
    { "fr": "Escargot" },
    { "fr": "Escrime" },
    { "fr": "Espagne" },
    { "fr": "Essence" },
    { "fr": "États-Unis" },
    { "fr": "Été" },
    { "fr": "Étoile" },
    { "fr": "Étoile de mer" },
    { "fr": "Étoile filante" },
    { "fr": "Everest" },
    { "fr": "Facteur" },
    { "fr": "Fantôme" },
    { "fr": "Farine" },
    { "fr": "Fauteuil" },
    { "fr": "Fauteuil roulant" },
    { "fr": "Fée" },
    { "fr": "Fer à repasser" },
    { "fr": "Ferme" },
    { "fr": "Fesse" },
    { "fr": "Fête de la musique" },
    { "fr": "Fête des grands-mères" },
    { "fr": "Fête des mères" },
    { "fr": "Fête des pères" },
    { "fr": "Feutre" },
    { "fr": "Fève" },
    { "fr": "Ficelle" },
    { "fr": "Fièvre" },
    { "fr": "Fil" },
    { "fr": "Fille" },
    { "fr": "Flamant rose" },
    { "fr": "Fleur" },
    { "fr": "Fleuriste" },
    { "fr": "Flûte traversière" },
    { "fr": "Football" },
    { "fr": "Forgeron" },
    { "fr": "Fossile" },
    { "fr": "Four" },
    { "fr": "Fourchette" },
    { "fr": "Fourmi" },
    { "fr": "Fraise" },
    { "fr": "Framboise" },
    { "fr": "France" },
    { "fr": "Frein" },
    { "fr": "Frisbee" },
    { "fr": "Frites" },
    { "fr": "Fromage" },
    { "fr": "Front" },
    { "fr": "Fruit" },
    { "fr": "Fusée" },
    { "fr": "Fusée spatiale" },
    { "fr": "Gaïa" },
    { "fr": "Galère" },
    { "fr": "Galette" },
    { "fr": "Gandhi" },
    { "fr": "Gant" },
    { "fr": "Garage" },
    { "fr": "Garçon" },
    { "fr": "Gardien" },
    { "fr": "Gare" },
    { "fr": "Gargamel" },
    { "fr": "Gâteau" },
    { "fr": "Gaulois" },
    { "fr": "Géant" },
    { "fr": "Genou" },
    { "fr": "Géologie" },
    { "fr": "Girafe" },
    { "fr": "Glaçon" },
    { "fr": "Gobelet" },
    { "fr": "Golf" },
    { "fr": "Gomme" },
    { "fr": "Gorille" },
    { "fr": "Grande Muraille de Chine" },
    { "fr": "Grèce" },
    { "fr": "Grenouille" },
    { "fr": "Griffe" },
    { "fr": "Groseille" },
    { "fr": "Grotte" },
    { "fr": "Grue" },
    { "fr": "Gruyère" },
    { "fr": "Guépard" },
    { "fr": "Guêpe" },
    { "fr": "Guirlande" },
    { "fr": "Gymnase" },
    { "fr": "Gymnastique" },
    { "fr": "Hadès" },
    { "fr": "Halloween" },
    { "fr": "Hamster" },
    { "fr": "Handball" },
    { "fr": "Haricot vert" },
    { "fr": "Hélicoptère" },
    { "fr": "Héphaïstos" },
    { "fr": "Héra" },
    { "fr": "Héraclès" },
    { "fr": "Herbe" },
    { "fr": "Hérisson" },
    { "fr": "Hermès" },
    { "fr": "Hibou" },
    { "fr": "Hippocampe" },
    { "fr": "Hippopotame" },
    { "fr": "Hirondelle" },
    { "fr": "Hiver" },
    { "fr": "Hockey sur glace" },
    { "fr": "Hôpital" },
    { "fr": "Hoquet" },
    { "fr": "Horloge" },
    { "fr": "Horus" },
    { "fr": "Hôtel" },
    { "fr": "Huile" },
    { "fr": "Husky" },
    { "fr": "Icare" },
    { "fr": "Idéfix" },
    { "fr": "Imprimante" },
    { "fr": "Infirmier" },
    { "fr": "Informaticien" },
    { "fr": "Insecte" },
    { "fr": "Italie" },
    { "fr": "Jambe" },
    { "fr": "Jambon" },
    { "fr": "Janus" },
    { "fr": "Japon" },
    { "fr": "Jeanne d'Arc" },
    { "fr": "Jolly Jumper" },
    { "fr": "Jongler" },
    { "fr": "Jongleur" },
    { "fr": "Joue" },
    { "fr": "Journaliste" },
    { "fr": "Journée internationale des femmes" },
    { "fr": "Judo" },
    { "fr": "Jumelles" },
    { "fr": "Jument" },
    { "fr": "Jupe" },
    { "fr": "Jus d'orange" },
    { "fr": "Kangourou" },
    { "fr": "Karaté" },
    { "fr": "Ketchup" },
    { "fr": "Kilt" },
    { "fr": "Kiwi" },
    { "fr": "La Reine des neiges" },
    { "fr": "La Schtroumpfette" },
    { "fr": "Lacet" },
    { "fr": "Lait" },
    { "fr": "Lancer du disque" },
    { "fr": "Langue" },
    { "fr": "Lapin" },
    { "fr": "Larme" },
    { "fr": "Las Vegas" },
    { "fr": "Lave-linge" },
    { "fr": "Le Capitaine Haddock" },
    { "fr": "Le Grand Schtroumpf" },
    { "fr": "Le Marsupilami" },
    { "fr": "Le Monde de Nemo" },
    { "fr": "Le Petit Prince" },
    { "fr": "Le Roi lion" },
    { "fr": "Le Schtroumpf à lunettes" },
    { "fr": "Léopard" },
    { "fr": "Les Schtroumpfs" },
    { "fr": "Lèvre" },
    { "fr": "Lèvres" },
    { "fr": "Lézard" },
    { "fr": "Libellule" },
    { "fr": "Libraire" },
    { "fr": "Licorne" },
    { "fr": "Lièvre" },
    { "fr": "Limace" },
    { "fr": "Lion" },
    { "fr": "Lire" },
    { "fr": "Lit" },
    { "fr": "Livre" },
    { "fr": "Louche" },
    { "fr": "Loup" },
    { "fr": "Loupe" },
    { "fr": "Loup-Garou" },
    { "fr": "Lucky Luke" },
    { "fr": "Luge" },
    { "fr": "Lune" },
    { "fr": "Lunettes" },
    { "fr": "Lunettes de soleil" },
    { "fr": "Lutin" },
    { "fr": "Maçon" },
    { "fr": "Magicien" },
    { "fr": "Maillot" },
    { "fr": "Maillot de bain" },
    { "fr": "Main" },
    { "fr": "Maison" },
    { "fr": "Manège" },
    { "fr": "Mante religieuse" },
    { "fr": "Manteau" },
    { "fr": "Marguerite" },
    { "fr": "Marie Curie" },
    { "fr": "Marin" },
    { "fr": "Marionnette" },
    { "fr": "Marmotte" },
    { "fr": "Marteau" },
    { "fr": "Médecin" },
    { "fr": "Médecine" },
    { "fr": "Médicament" },
    { "fr": "Méduse" },
    { "fr": "Melon" },
    { "fr": "Menton" },
    { "fr": "Merlin l'Enchanteur" },
    { "fr": "Métal" },
    { "fr": "Météorite" },
    { "fr": "Métro" },
    { "fr": "Mexique" },
    { "fr": "Mickey Mouse" },
    { "fr": "Microscope" },
    { "fr": "Mie" },
    { "fr": "Miel" },
    { "fr": "Mille-pattes" },
    { "fr": "Milou" },
    { "fr": "Minnie Mouse" },
    { "fr": "Minotaure" },
    { "fr": "Miroir" },
    { "fr": "Moineau" },
    { "fr": "Monstre du Loch Ness" },
    { "fr": "Mont Blanc" },
    { "fr": "Montagne" },
    { "fr": "Montgolfière" },
    { "fr": "Montre" },
    { "fr": "Morse (animal)" },
    { "fr": "Moteur" },
    { "fr": "Moto" },
    { "fr": "Mouche" },
    { "fr": "Mouchoir" },
    { "fr": "Mouette" },
    { "fr": "Moufle" },
    { "fr": "Moule" },
    { "fr": "Moulin" },
    { "fr": "Moustache" },
    { "fr": "Moustique" },
    { "fr": "Moutarde" },
    { "fr": "Mouton" },
    { "fr": "Mûre" },
    { "fr": "Musique" },
    { "fr": "Nager" },
    { "fr": "Nain" },
    { "fr": "Nappe" },
    { "fr": "Nasique" },
    { "fr": "Navet" },
    { "fr": "Neil Armstrong" },
    { "fr": "Nez" },
    { "fr": "Nid" },
    { "fr": "Nil" },
    { "fr": "Noël" },
    { "fr": "Noisette" },
    { "fr": "Noix" },
    { "fr": "Nombril" },
    { "fr": "Nouvel An Chinois" },
    { "fr": "Noyau" },
    { "fr": "Obélix" },
    { "fr": "Odin" },
    { "fr": "Œil" },
    { "fr": "Œuf" },
    { "fr": "Ogre" },
    { "fr": "Oie" },
    { "fr": "Oignon" },
    { "fr": "Oiseau" },
    { "fr": "Olive" },
    { "fr": "Ongle" },
    { "fr": "ONU (Organisation des Nations unies)" },
    { "fr": "Opticien" },
    { "fr": "Or" },
    { "fr": "Orange" },
    { "fr": "Ordinateur" },
    { "fr": "Ordonnance" },
    { "fr": "Oreille" },
    { "fr": "Oreiller" },
    { "fr": "Ornithorynque" },
    { "fr": "Orque" },
    { "fr": "Orteil" },
    { "fr": "Os" },
    { "fr": "Otarie" },
    { "fr": "Ours" },
    { "fr": "Ours blanc" },
    { "fr": "Oursin" },
    { "fr": "Ouvre-boîte" },
    { "fr": "Ouvrier" },
    { "fr": "Paille" },
    { "fr": "Pain" },
    { "fr": "Palais" },
    { "fr": "Pamplemousse" },
    { "fr": "Panda" },
    { "fr": "Pansement" },
    { "fr": "Pantalon" },
    { "fr": "Panthère" },
    { "fr": "Papier alu" },
    { "fr": "Papillon" },
    { "fr": "Parapluie" },
    { "fr": "Parasol" },
    { "fr": "Paris" },
    { "fr": "Pâte" },
    { "fr": "Pâte à modeler" },
    { "fr": "Pâtes" },
    { "fr": "Pâtisserie" },
    { "fr": "Patte" },
    { "fr": "Peau" },
    { "fr": "Pêche" },
    { "fr": "Pêcheur" },
    { "fr": "Pédale" },
    { "fr": "Pégase" },
    { "fr": "Peigne" },
    { "fr": "Pélican" },
    { "fr": "Pelle" },
    { "fr": "Peluche" },
    { "fr": "Perceuse" },
    { "fr": "Perle" },
    { "fr": "Perroquet" },
    { "fr": "Persil" },
    { "fr": "Pétanque" },
    { "fr": "Petit pois" },
    { "fr": "Pharmacie" },
    { "fr": "Phénix" },
    { "fr": "Phoque" },
    { "fr": "Piano" },
    { "fr": "Picsou" },
    { "fr": "Pied" },
    { "fr": "Pieuvre" },
    { "fr": "Pigeon" },
    { "fr": "Pinceau" },
    { "fr": "Pinocchio" },
    { "fr": "Pion" },
    { "fr": "Piquer" },
    { "fr": "Piqûre" },
    { "fr": "Pizza" },
    { "fr": "Planche" },
    { "fr": "Planche à découper" },
    { "fr": "Plastique" },
    { "fr": "Plâtre" },
    { "fr": "Pleurer" },
    { "fr": "Plombier" },
    { "fr": "Plume" },
    { "fr": "Pneu" },
    { "fr": "Poêle" },
    { "fr": "Poignet" },
    { "fr": "Poing" },
    { "fr": "Poire" },
    { "fr": "Poireau" },
    { "fr": "Poison" },
    { "fr": "Poisson" },
    { "fr": "Police" },
    { "fr": "Pomme" },
    { "fr": "Pomme de terre" },
    { "fr": "Pompier" },
    { "fr": "Poney" },
    { "fr": "Pont" },
    { "fr": "Popcorn" },
    { "fr": "Port" },
    { "fr": "Porte" },
    { "fr": "Portemanteau" },
    { "fr": "Poséidon" },
    { "fr": "Poubelle" },
    { "fr": "Pouce" },
    { "fr": "Poulain" },
    { "fr": "Poule" },
    { "fr": "Poulet" },
    { "fr": "Poumon" },
    { "fr": "Poupée" },
    { "fr": "Poussette" },
    { "fr": "Poussin" },
    { "fr": "Prince" },
    { "fr": "Princesse" },
    { "fr": "Printemps" },
    { "fr": "Prométhée" },
    { "fr": "Prune" },
    { "fr": "Puissance 4" },
    { "fr": "Pull-over" },
    { "fr": "Purée" },
    { "fr": "Putois" },
    { "fr": "Puzzle" },
    { "fr": "Pyjama" },
    { "fr": "Pyramides d'Égypte" },
    { "fr": "Quetzalcoatl" },
    { "fr": "Queue" },
    { "fr": "Raclette" },
    { "fr": "Radiateur" },
    { "fr": "Radio" },
    { "fr": "Radis" },
    { "fr": "Raisin" },
    { "fr": "Rantanplan" },
    { "fr": "Raquette" },
    { "fr": "Rasoir" },
    { "fr": "Rat" },
    { "fr": "Râteau" },
    { "fr": "Raton laveur" },
    { "fr": "Rê" },
    { "fr": "Recette" },
    { "fr": "Réfrigérateur" },
    { "fr": "Reine" },
    { "fr": "Renard" },
    { "fr": "Requin" },
    { "fr": "Restaurant" },
    { "fr": "Réveil" },
    { "fr": "Rêver" },
    { "fr": "Rhinocéros" },
    { "fr": "Rideau" },
    { "fr": "Riz" },
    { "fr": "Robe" },
    { "fr": "Robinet" },
    { "fr": "Robinson Crusoé" },
    { "fr": "Robot" },
    { "fr": "Roi" },
    { "fr": "Roi Arthur" },
    { "fr": "Rome" },
    { "fr": "Roue" },
    { "fr": "Rouge-Gorge" },
    { "fr": "Rouleau à pâtisserie" },
    { "fr": "Sable" },
    { "fr": "Saint-Valentin" },
    { "fr": "Salade" },
    { "fr": "Salade de fruits" },
    { "fr": "Saladier" },
    { "fr": "Salière" },
    { "fr": "Sang" },
    { "fr": "Sanglier" },
    { "fr": "Sapin" },
    { "fr": "Sardine" },
    { "fr": "S'asseoir" },
    { "fr": "Satyre" },
    { "fr": "Saumon" },
    { "fr": "Saut à la perche" },
    { "fr": "Savon" },
    { "fr": "Scie" },
    { "fr": "Science" },
    { "fr": "Scooter" },
    { "fr": "Scorpion" },
    { "fr": "Se baisser" },
    { "fr": "Se coucher" },
    { "fr": "Se déshabiller" },
    { "fr": "Se laver" },
    { "fr": "Se maquiller" },
    { "fr": "Se moucher" },
    { "fr": "Seau" },
    { "fr": "Sein" },
    { "fr": "Sel" },
    { "fr": "Serpent" },
    { "fr": "Serpent de mer" },
    { "fr": "Serrure" },
    { "fr": "Serviette" },
    { "fr": "Shampooing" },
    { "fr": "Sherlock Holmes" },
    { "fr": "Shiva" },
    { "fr": "Singe" },
    { "fr": "Sirène" },
    { "fr": "Ski" },
    { "fr": "Soleil" },
    { "fr": "Sonnette" },
    { "fr": "Sorcière" },
    { "fr": "Soucoupe volante" },
    { "fr": "Soupe" },
    { "fr": "Sourcil" },
    { "fr": "Souris" },
    { "fr": "Sous-marin" },
    { "fr": "Sphinx" },
    { "fr": "Squelette" },
    { "fr": "Statue de la Liberté" },
    { "fr": "Stéthoscope" },
    { "fr": "Stylo" },
    { "fr": "Sucre" },
    { "fr": "Superman" },
    { "fr": "Table" },
    { "fr": "Tableau" },
    { "fr": "Tablette" },
    { "fr": "Tablier" },
    { "fr": "Taille-crayon" },
    { "fr": "Talon" },
    { "fr": "Tambour" },
    { "fr": "Tandem" },
    { "fr": "Tapis" },
    { "fr": "Tapis volant" },
    { "fr": "Tarte" },
    { "fr": "Tartine" },
    { "fr": "Tasse" },
    { "fr": "Taupe" },
    { "fr": "Taureau" },
    { "fr": "Télécommande" },
    { "fr": "Téléphone" },
    { "fr": "Téléphone portable" },
    { "fr": "Télévision" },
    { "fr": "Tennis" },
    { "fr": "Tente" },
    { "fr": "Tête" },
    { "fr": "Téter" },
    { "fr": "Thé" },
    { "fr": "Thermomètre" },
    { "fr": "Thor" },
    { "fr": "Thot" },
    { "fr": "Tigre" },
    { "fr": "Timbre" },
    { "fr": "Tiroir" },
    { "fr": "Toboggan" },
    { "fr": "Tomate" },
    { "fr": "Torchon" },
    { "fr": "Tortue" },
    { "fr": "Tour de Pise" },
    { "fr": "Tour Eiffel" },
    { "fr": "Tournevis" },
    { "fr": "Toutatis" },
    { "fr": "Tracteur" },
    { "fr": "Train" },
    { "fr": "Traîneau" },
    { "fr": "Trampoline" },
    { "fr": "Trésor" },
    { "fr": "Tricycle" },
    { "fr": "Trompette" },
    { "fr": "Trône" },
    { "fr": "Trottinette" },
    { "fr": "Trou noir" },
    { "fr": "Trousse" },
    { "fr": "Truie" },
    { "fr": "Tuyau" },
    { "fr": "UE (Union européenne)" },
    { "fr": "Ulysse" },
    { "fr": "Un Saint-Bernard" },
    { "fr": "Un, deux, trois, soleil" },
    { "fr": "Une Laisse" },
    { "fr": "Une souris verte" },
    { "fr": "Univers" },
    { "fr": "Urine" },
    { "fr": "Usine" },
    { "fr": "Vache" },
    { "fr": "Valise" },
    { "fr": "Valkyrie" },
    { "fr": "Vampire" },
    { "fr": "Vase" },
    { "fr": "Vautour" },
    { "fr": "Veau" },
    { "fr": "Vélo" },
    { "fr": "Venise" },
    { "fr": "Ventre" },
    { "fr": "Ver de terre" },
    { "fr": "Verre" },
    { "fr": "Vétérinaire" },
    { "fr": "Viande" },
    { "fr": "Vikings" },
    { "fr": "Vin" },
    { "fr": "Vis" },
    { "fr": "Visage" },
    { "fr": "Voile" },
    { "fr": "Voiture" },
    { "fr": "Volcan" },
    { "fr": "Volet" },
    { "fr": "Xylophone" },
    { "fr": "Yahourt" },
    { "fr": "Yangzi Jiang" },
    { "fr": "Yéti" },
    { "fr": "Zèbre" },
    { "fr": "Zeus" },
    { "fr": "Zombie" },
    { "fr": "Zoo" },
];