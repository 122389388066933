import _ from 'lodash';
import { INVALID_MOVE, ActivePlayers } from 'boardgame.io/core';
import utils from './utils.js';
import dict from './dictionnary.js';

const START_HAND_SIZE = 4;

const chooseSecrets = () => {
    let pivot = Math.floor(dict.length/2);
    if (_.random(true) < 0.5) {
        return [_.random(0, pivot), _.random(pivot+1, dict.length-1)];
    }
    return [_.random(pivot+1, dict.length-1), _.random(0, pivot)];
}

const newGame = () => {
    let deck = _.shuffle(utils.DECK);
    let hands = [];
    for (let i = 0; i < 4; i++) {
        hands.push(deck.slice(i * START_HAND_SIZE, (i + 1) * START_HAND_SIZE));
    }
    const offset = START_HAND_SIZE*4;
    return {
        deck: deck.slice(offset+4),
        asset: null,
        table: [
            deck[offset],
            deck[offset+1],
            deck[offset+2],
            deck[offset+3],
        ],
        hands: hands,
        
        ready:    [false, false, false, false],
        continue: [false, false, false, false],

        last_move_ts: null,

        secrets: chooseSecrets(),

        hand_to_uncover: null,
        party_over: null,
    }
}

const KantCopy = {
    name: "kantcopy",
    phases: {
        ready: {
            start: true,
            moves: {
                ready: (G, ctx) => {
                    let playerIdx = G.playerIdx[ctx.playerID];
                    if (G.ready[playerIdx]) return G;
                    let ready = _.cloneDeep(G.ready);
                    ready[playerIdx] = true;
                    return {
                        ...G,
                        ready: ready,
                    };
                },
            },
            endIf: (G, ctx) => G.ready[0] && G.ready[1] && G.ready[2] && G.ready[3],
            turn: {
                activePlayers: ActivePlayers.ALL,
            },
            next: 'play',
        },
        play: {
            onBegin: (G, ctx) => ({...G, last_move_ts: Date.now()}),
            moves: {
                swap: (G, ctx, hand_card, table_card) => {
                    let playerIdx = G.playerIdx[ctx.playerID];
                    if (!utils.isValidSwapMove(G, playerIdx, hand_card, table_card).valid) return INVALID_MOVE;

                    let hands = _.cloneDeep(G.hands);
                    let table = _.cloneDeep(G.table);

                    // swap cards
                    let tableIdx = _.findIndex(table, (c) => c === table_card);
                    let handIdx = _.findIndex(hands[playerIdx], (c) => c === hand_card);
                    table[tableIdx] = hand_card;
                    hands[playerIdx][handIdx] = table_card;

                    return {
                        ...G,
                        hands: hands,
                        table: table,
                        last_move_ts: Date.now(),
                    };
                },
                lock: (G, ctx) => {
                    let playerIdx = G.playerIdx[ctx.playerID];
                    // only player 0 can do this move
                    if (playerIdx !== 0) return G;
                    // do not do any thing if no last_move is known
                    if (Date.now()-G.last_move_ts < utils.MOVE_TIMEOUT_S*1000) return G;

                    // this move is triggered automatically
                    // if no player makes a move after some time
                    let table = _.cloneDeep(G.table);
                    let deck = _.cloneDeep(G.deck);

                    deck.push(table[0]);
                    deck.push(table[1]);
                    deck.push(table[2]);
                    deck.push(table[3]);
                    table = deck.slice(0, 4);
                    deck = _.shuffle(deck.slice(4));

                    return {
                        ...G,
                        deck: deck,
                        table: table,
                        last_move_ts: Date.now(),
                    };
                },
                kant: (G, ctx) => {
                    // my friend has all 4 cards
                    let playerIdx = G.playerIdx[ctx.playerID];

                    return {
                        ...G,
                        hand_to_uncover: (playerIdx+2)%4,
                        shouter: playerIdx,
                    };
                },
                copy: (G, ctx) => {
                    // one of my opponents has all 4 cards
                    let playerIdx = G.playerIdx[ctx.playerID];

                    return {
                        ...G,
                        hand_to_uncover: (playerIdx+1)%4,
                        shouter: playerIdx,
                    };
                }, 
            },
            endIf: (G, ctx) => G.hand_to_uncover !== null,
            onEnd: (G, ctx) => {
                let scores = _.cloneDeep(G.scores);
                const shouter = G.shouter;
                
                let winner = null;

                if (G.hand_to_uncover % 2 === shouter % 2) {
                    // kant
                    const hand = G.hands[G.hand_to_uncover];
                    if (hand[0].substr(1) === hand[1].substr(1) && hand[0].substr(1) === hand[2].substr(1) && hand[0].substr(1) === hand[3].substr(1)) {
                        winner = shouter % 2;
                    } else {
                        winner = (shouter+1) % 2;
                    }
                } else {
                    // copy
                    const hand1 = G.hands[G.hand_to_uncover];
                    const hand2 = G.hands[(G.hand_to_uncover+2)%4];
                    if ((hand1[0].substr(1) === hand1[1].substr(1) && hand1[0].substr(1) === hand1[2].substr(1) && hand1[0].substr(1) === hand1[3].substr(1)) ||
                        (hand2[0].substr(1) === hand2[1].substr(1) && hand2[0].substr(1) === hand2[2].substr(1) && hand2[0].substr(1) === hand2[3].substr(1))) {
                        winner = shouter % 2;
                    } else {
                        winner = (shouter+1) % 2;
                    }
                }

                scores[winner]++;
                return {
                    ...G,
                    scores: scores,
                    party_over: {
                        winner,
                    },
                    should_continue: 0,
                };
            },
            turn: {
                activePlayers: ActivePlayers.ALL,
            },
            next: 'continue',
        },
        continue: {
            moves: {
                continue: (G, ctx) => {
                    let playerIdx = G.playerIdx[ctx.playerID];
                    if (G.continue[playerIdx]) return G;
                    let c = _.cloneDeep(G.continue);
                    c[playerIdx] = true;
                    return {
                        ...G,
                        continue: c,
                    };
                },
            },
            turn: {
                activePlayers: ActivePlayers.ALL,
            },
            endIf: (G, ctx) => G.continue[0] && G.continue[1] && G.continue[2] && G.continue[3],
            onEnd: (G, ctx) => {
                return {
                    ...G,
                    ...(newGame()),
                };
            },
            next: 'ready',
        },
        endIf: (G, ctx) => false,
    },

    events: {
        endGame: false,
    },

    playerView: (G, ctx, playerID) => {
        if (G.hand_to_uncover !== null) return G;
        let hands = _.cloneDeep(G.hands);
        for (let opponentID in G.playerIdx) {
            if (opponentID === playerID) continue;
            let idx = utils.playerIDtoIdx(G, opponentID);
            hands[idx] = Array(hands[idx].length).fill(0);
        }
        let secrets = _.cloneDeep(G.secrets);
        let playerIdx = utils.playerIDtoIdx(G, playerID);
        secrets[(playerIdx+1)%2] = null;
        return {
            ...G,
            deck: Array(G.deck.length).fill(0),
            hands: hands,
            secrets: secrets,
        }
    },

    setup: (ctx) => {
        let game = newGame();
        let k = 0, playerIdx = {};
        for (let id of ctx.playOrder) {
            playerIdx[id] = k;
            k++;
        }
        return {
            ...game,
            playerIdx: playerIdx,
            scores: [0, 0],
        };
    },
};



export default KantCopy;