import React from 'react';
import { View, TextInput } from 'react-native';
import { ROOM_BOX_SIZE } from './config';
import Button from './Button';


const QuickForm = ({ onSubmit, onChange, error, BtnTitle, value, placeholder, maxLength, BtnIcon, BtnIconSize, noMarginTop }) => (
    <View style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: (noMarginTop ? null : 20), marginBottom: 15, width: ROOM_BOX_SIZE }}>
        <TextInput style={{ height: 50, padding: 10, marginRight: 15, borderColor: (error) ? "red" : "white", borderWidth: 1, flex: 1, color: "white" }} placeholderTextColor="rgb(168, 155, 120)" onSubmitEditing={onSubmit} onChange={(e) => onChange(e.target.value)} onChangeText={onChange} value={value} placeholder={placeholder} maxLength={maxLength} />
        <Button style={{ padding: 10, alignSelf: "stretch" }} icon={BtnIcon} iconSize={BtnIconSize} title={BtnTitle} onPress={onSubmit}></Button>
    </View>
)

export default QuickForm;