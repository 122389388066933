import React from 'react';
import { Animated, View, Text, TouchableHighlight, Dimensions, ImageBackground, Easing } from 'react-native';
import Constants from 'expo-constants';
import {CircularProgress} from 'react-native-svg-circular-progress';
import AnimateNumber from './AnimateNumber';
import _ from 'lodash';
import dict from '../core/dictionnary.js';
import utils from '../core/utils.js';
import ai from '../core/ai.js';
import Button from './Button';
import IMGS_SRC from './img';
import storage from './storage';
import HelpTooltipDisplay from './HelpTooltip';


const WIN_DIM = Dimensions.get('window');
const SCREEN_WIDTH = Math.floor(WIN_DIM.width);
const SCREEN_HEIGHT = Math.floor(WIN_DIM.height);

// --- CONSTANTS

const PADDING = 5;
const CARD_BORDER_SIZE = 1;
const HAND_BORDER_SIZE = 2;
const ORIGINAL_CARD_WIDTH = 200;
const ORIGINAL_CARD_HEIGHT = 318;
const CARD_SIZE_RATIO = ORIGINAL_CARD_HEIGHT / ORIGINAL_CARD_WIDTH;
const SHOWN_CARD_WIDTH_PCT = 0.6;
const SMALL_CARD_SIZE_PCT = 0.8;

const HEADER_MENU_HEIGHT = 60;

// --- PLAYER NAME

const PLAYER_NAME_HEIGHT = 30;

// --- CARDS

const CARD_SIZE_PCT_IF_FULL_HAND_PLAYER_SHOWN = (SCREEN_WIDTH - 2 * PADDING + 7 * CARD_BORDER_SIZE - 6 * HAND_BORDER_SIZE) / ((7 * SHOWN_CARD_WIDTH_PCT + 1) * ORIGINAL_CARD_WIDTH);
const CARD_SIZE_PCT_IF_FULL_HEIGHT_SHOWN = (SCREEN_HEIGHT - (Constants.statusBarHeight || 0) - 2 * PADDING - HEADER_MENU_HEIGHT - (2 * PLAYER_NAME_HEIGHT + 6 * PADDING + 6 * HAND_BORDER_SIZE + (2 * (7 * SHOWN_CARD_WIDTH_PCT + 1) - 3) * CARD_BORDER_SIZE)) / (ORIGINAL_CARD_WIDTH * (CARD_SIZE_RATIO * (SMALL_CARD_SIZE_PCT + 1) + SMALL_CARD_SIZE_PCT * (7 * SHOWN_CARD_WIDTH_PCT + 1)));
const CARD_SIZE_PCT = Math.min(
    1,
    CARD_SIZE_PCT_IF_FULL_HAND_PLAYER_SHOWN,
    CARD_SIZE_PCT_IF_FULL_HEIGHT_SHOWN,
);

const CARD_WIDTH = Math.floor(CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH);
const CARD_HEIGHT = Math.floor(CARD_SIZE_PCT * ORIGINAL_CARD_HEIGHT);
const FULL_CARD_WIDTH = CARD_WIDTH + CARD_BORDER_SIZE * 2;
const FULL_CARD_HEIGHT = CARD_HEIGHT + CARD_BORDER_SIZE * 2;

const SMALL_CARD_WIDTH = Math.floor(CARD_WIDTH * SMALL_CARD_SIZE_PCT);
const SMALL_CARD_HEIGHT = Math.floor(CARD_HEIGHT * SMALL_CARD_SIZE_PCT);
const FULL_SMALL_CARD_WIDTH = SMALL_CARD_WIDTH + CARD_BORDER_SIZE * 2;
const FULL_SMALL_CARD_HEIGHT = SMALL_CARD_HEIGHT + CARD_BORDER_SIZE * 2;

// --- HAND

const PLAYER_HAND_WIDTH = (Math.floor(FULL_CARD_WIDTH * SHOWN_CARD_WIDTH_PCT) - CARD_BORDER_SIZE) * 7 + FULL_CARD_WIDTH;
const PLAYER_HAND_HEIGHT = FULL_CARD_HEIGHT;
const FULL_PLAYER_HAND_WIDTH = PLAYER_HAND_WIDTH + 2 * PADDING + 2 * HAND_BORDER_SIZE;
const FULL_PLAYER_HAND_HEIGHT = PLAYER_HAND_HEIGHT + 2 * PADDING + 2 * HAND_BORDER_SIZE;

const OTHER_HAND_WIDTH = (Math.floor(FULL_SMALL_CARD_WIDTH * SHOWN_CARD_WIDTH_PCT) - CARD_BORDER_SIZE) * 7 + FULL_SMALL_CARD_WIDTH;
const OTHER_HAND_HEIGHT = FULL_SMALL_CARD_HEIGHT;
const FULL_OTHER_HAND_WIDTH = OTHER_HAND_WIDTH + 2 * PADDING + 2 * HAND_BORDER_SIZE;
const FULL_OTHER_HAND_HEIGHT = OTHER_HAND_HEIGHT + 2 * PADDING + 2 * HAND_BORDER_SIZE;

const PLAYER_NAME_WIDTH = FULL_OTHER_HAND_WIDTH;

// --- ACTION BUTTON

const ACTION_BTN_WIDTH = FULL_SMALL_CARD_HEIGHT * 2;
const ACTION_BTN_HEIGHT = Math.min(32, Math.floor((FULL_SMALL_CARD_HEIGHT - PADDING) / 2));

// --- TABLE CENTER

const TIMER_CIRCLE_SIZE = 45;
const TIMER_PROGRESS_WIDTH = Math.floor(TIMER_CIRCLE_SIZE/2 - 6);
const TABLE_HEIGHT = FULL_SMALL_CARD_HEIGHT*2 + TIMER_CIRCLE_SIZE + ACTION_BTN_HEIGHT + TIMER_CIRCLE_SIZE + 4*PADDING;
const TABLE_WIDTH = FULL_SMALL_CARD_HEIGHT*2 + TIMER_CIRCLE_SIZE;


const HEADER_MENU_WIDTH = Math.min(SCREEN_WIDTH, (FULL_OTHER_HAND_HEIGHT + PLAYER_NAME_HEIGHT) * 2 + TABLE_WIDTH);


class Card extends React.Component {
    animV = new Animated.Value(0)

    state = {
        animV: this.animV,
        raiseAnimMine: Animated.multiply(this.animV, -CARD_HEIGHT),
        raiseAnimOther: Animated.multiply(this.animV, -SMALL_CARD_HEIGHT),
    };

    componentDidMount() {
        if (this.props.tierceRaise) { this.animV.setValue(0.33); return this.animateFullRaise(); }
        if (this.props.raise) return this.animateSemiRaise();
    }

    componentDidUpdate(prevProps) {
        if (this.props.tierceRaise && !prevProps.tierceRaise) return this.animateFullRaise();
        if (!this.props.tierceRaise && prevProps.tierceRaise) return this.animateFold();
        if (this.props.raise && !prevProps.raise) return this.animateSemiRaise();
        if (!this.props.raise && prevProps.raise) return this.animateFold();
    }

    componentWillUnmount() {
        if (this.animRaise) this.animRaise.stop();
        if (this.animFold) this.animFold.stop();
    }

    animateSemiRaise = () => {
        this.animRaise = Animated.timing(this.state.animV, {
            toValue: 0.33,
            duration: 150,
        });
        this.animRaise.start();
    };

    animateFullRaise = () => {
        this.animRaise = Animated.timing(this.state.animV, {
            toValue: 1,
            duration: 350,
        });
        this.animRaise.start();
    };

    animateFold = () => {
        this.animFold = Animated.timing(this.state.animV, {
            toValue: 0,
            duration: 150,
        });
        this.animFold.start();
    };

    onPress = () => {
        if (!this.props.onPress) return;
        return this.props.onPress(this.props.card);
    }

    render() {
        const { idx, card, isCropped, seat, active, inHand, zombie, error, settings } = this.props;
        const isMine = seat === 0;
        const isSmallCard = !(inHand && isMine);
        const { raiseAnimMine, raiseAnimOther } = this.state;

        const name = `CARD_${settings.cards}_${card || "cover"}_IMG`;

        let transforms = [];
        if (!inHand) {
            if (seat === 1) {
                transforms.push({ rotate: "270deg" });
            } else if (seat === 2) {
                transforms.push({ rotate: "180deg" });
            } else if (seat === 3) {
                transforms.push({ rotate: "90deg" });
            }
        } else {
            transforms.push({ translateY: isMine ? raiseAnimMine : raiseAnimOther });
        }

        let width = CARD_WIDTH, height = CARD_HEIGHT;
        if (isSmallCard) {
            width = SMALL_CARD_WIDTH; height = SMALL_CARD_HEIGHT;
        }
        if (isCropped) {
            width = Math.floor(width * SHOWN_CARD_WIDTH_PCT);
        }

        const img = (
            <View style={{
                width: width,
                height: height,
            }}>
                <Animated.Image
                    style={{
                        zIndex: 300 + (idx || 0),
                        margin: 0,
                        padding: 0,
                        width: (isSmallCard) ? SMALL_CARD_WIDTH : CARD_WIDTH,
                        height: (isSmallCard) ? SMALL_CARD_HEIGHT : CARD_HEIGHT,
                        borderWidth: CARD_BORDER_SIZE,
                        borderColor: (error) ? "red" : "grey",
                        borderRadius: (settings.cards === "FR")?"0.5em":0,
                        opacity: (zombie) ? 0.8 : null,
                        transform: transforms,
                    }}
                    source={IMGS_SRC[name]} />
            </View>

        );

        if (!active) {
            return img;
        }
        return (
            <TouchableHighlight onPress={this.onPress} underlayColor="transparent" activeOpacity={1}>
                {img}
            </TouchableHighlight>
        );
    }
}

class Hand extends React.Component {

    render() {
        const { settings, onPress, selected, phase, playerName, cards, seat, isReady, isContinue } = this.props;
        let isMine = seat === 0;        
        let transforms = [];
        if (seat === 1) {
            transforms.push({ rotate: "270deg" });
        } else if (seat === 2) {
            transforms.push({ rotate: "180deg" });
        } else if (seat === 3) {
            transforms.push({ rotate: "90deg" });
        }

        const handSize = {
            width: (!isMine) ? FULL_OTHER_HAND_WIDTH : FULL_PLAYER_HAND_WIDTH,
            height: (!isMine) ? FULL_OTHER_HAND_HEIGHT : FULL_PLAYER_HAND_HEIGHT,
        };

        let containerSize = {
            width: handSize.width,
            height: handSize.height + PLAYER_NAME_HEIGHT,
        };
        if (seat % 2 === 1) {
            containerSize = {
                width: containerSize.height,
                height: containerSize.width,
            };
        }

        const isActive = (phase === "ready" && isReady) || (phase === "continue" && isContinue) || phase === "play";

        const playerNameComponent = (
            <View style={{
                width: PLAYER_NAME_WIDTH,
                height: PLAYER_NAME_HEIGHT,
                padding: PADDING,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
            }} >
                <Text style={{ color: ((isActive) ? "white" : "rgb(168, 155, 120)"), transform: (seat === 2) ? [{ scaleY: -1 }, { scaleX: -1 }] : [] }}>
                    {playerName}
                </Text>
            </View>
        );

        return (
            <View style={{
                alignItems: "center",
                justifyContent: "center",
                ...containerSize,
            }} >
                <View style={{
                    alignItems: "center",
                    justifyContent: "center",
                    transform: transforms,
                }}>
                    {playerNameComponent}
                    <View style={{
                        alignItems: "center",
                        justifyContent: "center",
                        borderStyle: "solid",
                        borderColor: (isActive) ? "white" : "rgb(168, 155, 120)",
                        backgroundColor: isActive ? "rgb(168, 155, 120)" : null,
                        borderWidth: HAND_BORDER_SIZE,
                        width: (seat !== 0) ? FULL_OTHER_HAND_WIDTH : FULL_PLAYER_HAND_WIDTH,
                        height: (seat !== 0) ? FULL_OTHER_HAND_HEIGHT : FULL_PLAYER_HAND_HEIGHT,
                        padding: PADDING,
                    }}>
                        <View style={{
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            {_.map(cards, (card, idx) => (
                                <Card key={`${seat}-${(isMine) ? card : idx}`}
                                    card={card}
                                    settings={settings}
                                    seat={seat}
                                    active={isMine && isActive}
                                    inHand
                                    idx={idx}
                                    isCropped={idx < cards.length - 1}
                                    onPress={onPress}
                                    raise={card === selected} />
                            ))}
                        </View>
                    </View>
                </View >
            </View>
        );
    }
}

class Table extends React.Component {
    render() {
        const {
            settings,
            onReady,
            onPress,
            ImReady,
            onKant,
            onCopy,
            gameOver,
            displayedTimerValue,
            myIdx,
            phase,
            table,
            onAllCardsLayout,
            mySecret } = this.props;

        if (phase === "ready") {
            return (
                <View style={{ flexDirection: "column", alignItems: "center", justifyContent: "center", width: TABLE_WIDTH, height: TABLE_HEIGHT }} >
                    <Text style={{
                        color: "white"
                    }}>🤫 mot de passe:</Text>
                    <Text style={{
                        marginTop: PADDING,
                        color: "white",
                        fontWeight: "bold",
                    }}>
                        {dict[mySecret].fr}
                    </Text>
                    {(!ImReady)?
                    <Button onPress={onReady} title="OK" bold
                            style={{
                                width: ACTION_BTN_WIDTH,
                                height: ACTION_BTN_HEIGHT,
                                marginTop: 4 * PADDING,
                                zIndex: 600,
                                backgroundColor: "#0B6E4F",
                                color: "white"
                            }} /> :
                    <View style={{
                        marginTop: 4 * PADDING,
                        width: ACTION_BTN_WIDTH,
                        height: ACTION_BTN_HEIGHT}} />
                        }
                </View>
            )
        }

        if (phase === "continue") {
            return (
                <View style={{ flexDirection: "column", alignItems: "center", justifyContent: "center", width: TABLE_WIDTH, height: TABLE_HEIGHT }} >
                    {
                        (gameOver.winner === (myIdx + 1) % 2) ?
                        <Text style={{ color: "#e24c2c", fontWeight: "bold" }}>Perdu !</Text> :
                        <Text style={{ color: "#a6c64c", fontWeight: "bold" }}>Gagné !</Text>
                    }
                </View>
            );
        }

        return (
            <View onLayout={onAllCardsLayout} style={{ flexDirection: "column", justifyContent: "center", alignItems: "center", width: TABLE_WIDTH, height: TABLE_HEIGHT }} >
                <View style={{ width: TABLE_WIDTH, height: FULL_SMALL_CARD_HEIGHT, flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <Card active settings={settings} card={table[0]} seat={0} onPress={onPress} />
                    <View style={{ width: TIMER_CIRCLE_SIZE }} />
                    <Card active settings={settings} card={table[1]} seat={0} onPress={onPress} />
                </View>
                <View style={{ width: TABLE_WIDTH, height: TIMER_CIRCLE_SIZE, alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress fillColor="rgb(83, 64, 47)" donutColor="white" blankColor="rgb(83, 64, 47)" size={TIMER_CIRCLE_SIZE} progressWidth={TIMER_PROGRESS_WIDTH} percentage={displayedTimerValue*100}>
                        <View>
                            <Text style={{ color: "white", fontWeight: "bold" }}>{Math.ceil(displayedTimerValue*utils.MOVE_TIMEOUT_S)}</Text>
                        </View>
                    </CircularProgress>
                </View>
                <View style={{ width: TABLE_WIDTH, height: FULL_SMALL_CARD_HEIGHT, flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <Card active settings={settings} card={table[2]} seat={0} onPress={onPress} />
                    <View style={{ width: TIMER_CIRCLE_SIZE }} />
                    <Card active settings={settings} card={table[3]} seat={0} onPress={onPress} />
                </View>
                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", width: TABLE_WIDTH, height: ACTION_BTN_HEIGHT + TIMER_CIRCLE_SIZE + 4*PADDING }}>
                    <Button onPress={onKant} title="KANT" bold
                        style={{
                            marginBottom: 4*PADDING, marginTop: TIMER_CIRCLE_SIZE,
                            width: ACTION_BTN_WIDTH/2-(PADDING*2),
                            height: ACTION_BTN_HEIGHT,
                            backgroundColor: "#329f5b",
                            color: "white"
                        }} />
                    <View style={{ width: PADDING*4 }} />
                    <Button onPress={onCopy} title="COPY" bold
                        style={{
                            marginBottom: 4*PADDING, marginTop: TIMER_CIRCLE_SIZE,
                            width: ACTION_BTN_WIDTH/2-(PADDING*2),
                            height: ACTION_BTN_HEIGHT,
                            backgroundColor: "#ea7317",
                            color: "white"
                        }} />
                </View>
            </View>
        );
    };
}


class Game extends React.Component {

    state = {
        tooltipQueue: [],
        tooltip: {},
        partyOverShouterModal: {},
        partyOverModal: {},
        pushedWalkthrough: {},
        timerValue: new Animated.Value(0),
        displayedTimerValue: 1,
    };

    componentWillUnmount() {
        if (this.anim) this.anim.stop();
    }

    componentDidMount() {
        if (this.props.isAI) {
            this.offsetTimeout = setTimeout(() => {
                this.interval = setInterval(this.aiPlay, 1000);
            }, 1000 + 500*parseInt(this.props.playerID, 10));
            return;
        }
        this.state.timerValue.addListener((v) => this.setState({
            displayedTimerValue: 1-v.value,
        }));
        if (this.props.G && this.props.G.last_move_ts !== null) this.resetLockTimer(this.props.G.last_move_ts);
        if (this.props.G && this.props.G.party_over) {
            this.showPartyOverShouter();
        }
    }

    componentWillUnmount() {
        if (this.offsetTimeout) clearTimeout(this.offsetTimeout);
        if (this.interval) clearInterval(this.interval);
        if (this.anim) this.anim.stop();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.G && this.props.G && this.props.G.last_move_ts !== null) this.resetLockTimer(this.props.G.last_move_ts);
        if (prevProps.G && (prevProps.G.last_move_ts === null && this.props.G.last_move_ts !== null)) this.resetLockTimer(this.props.G.last_move_ts);
        if (prevProps.G && this.props.G &&
            prevProps.G.last_move_ts !== null && this.props.G.last_move_ts !== null) {
            
            // check if table has changed reset lock timer
            if (prevProps.G.last_move_ts !== this.props.G.last_move_ts) {
                this.resetLockTimer(this.props.G.last_move_ts);
            }
        }
        if (!prevProps.G && this.props.G && this.props.G && this.props.G.party_over) this.showPartyOverShouter();
        if (prevProps.G && (!prevProps.G.party_over && this.props.G.party_over)) this.showPartyOverShouter();
        if (prevProps.G && this.props.G &&
            !prevProps.G.party_over && this.props.G.party_over) {
            this.showPartyOverShouter();
        }
    }

    onGameLayout = (n) => {
        this.setState({
            gameLayout: n.nativeEvent.layout,
        }, () => {
            this.pushWalkThroughHowToPlayTooltipIfNot();
            this.checkShouterShow();
        })
    }

    onLayoutHeaderMenu = (n) => {
        this.setState({
            layoutHeaderMenu: n.nativeEvent.layout,
        }, this.pushWalkThroughHowToPlayTooltipIfNot)
    }

    onAllCardsLayout = (n) => {
        this.setState({
            layoutAllCards: n.nativeEvent.layout,
        }, this.pushWalkThroughHowToPlayTooltipIfNot)
    }

    onTableLayout = (n) => {
        this.setState({
            tableLayout: n.nativeEvent.layout,
        }, this.pushWalkThroughHowToPlayTooltipIfNot)
    }

    onTableContainerLayout = (n) => {
        this.setState({
            tableContainerLayout: n.nativeEvent.layout,
        }, this.pushWalkThroughHowToPlayTooltipIfNot)
    }

    onMyHandLayout = (n) => {
        this.setState({
            myHandLayout: n.nativeEvent.layout,
        }, () => {
            this.pushWalkThroughHowToPlayTooltipIfNot();
            this.checkShouterShow();
        })
    }

    onPlayer1HandLayout = (n) => {
        this.setState({
            player1HandLayout: n.nativeEvent.layout,
        }, this.checkShouterShow)
    }

    onPlayer2HandLayout = (n) => {
        this.setState({
            player2HandLayout: n.nativeEvent.layout,
        }, this.checkShouterShow)
    }

    onPlayer3HandLayout = (n) => {
        this.setState({
            player3HandLayout: n.nativeEvent.layout,
        }, this.checkShouterShow)
    }

    pushTooltip = (id, newTooltip, cb) => {
        const { tooltipQueue, tooltip, pushedWalkthrough } = this.state;
        const newPushedWalkthrough = (id) ? { ...pushedWalkthrough, [id]: true } : pushedWalkthrough;
        if (!tooltip.show) {
            this.setState({
                tooltip: { ...newTooltip, id: id },
                pushedWalkthrough: newPushedWalkthrough,
            }, cb);
        } else {
            this.setState({
                tooltipQueue: [{ ...newTooltip, id: id }, ...tooltipQueue],
                pushedWalkthrough: newPushedWalkthrough,
            }, cb);
        }
    }

    onHideTooltip = () => {
        const { tooltipQueue, tooltip } = this.state;
        if (tooltip.id) storage.setItem(tooltip.id, "true", () => { });
        if (tooltipQueue.length === 0) {
            this.setState({
                tooltip: { ...tooltip, show: false },
            });
        } else {
            this.setState({
                tooltip: tooltipQueue[0],
                tooltipQueue: tooltipQueue.slice(1),
            });
        }
    }

    pushWalkThroughHowToPlayTooltipIfNot = () => {
        const { gameLayout, layoutHeaderMenu, layoutAllCards, tableLayout, tableContainerLayout, myHandLayout, pushedWalkthrough } = this.state;
        const walkThroughFirstMove = "walkthrough-first-move";
        if (!pushedWalkthrough[walkThroughFirstMove] && layoutAllCards && gameLayout && myHandLayout && layoutHeaderMenu && tableLayout && tableContainerLayout) {
            storage.getItem(walkThroughFirstMove, (err, seen) => {
                if (seen) return;
                this.pushTooltip(walkThroughFirstMove, {
                    show: true,
                    text: "Appuyez sur une carte pour l'échanger avec une autre sur la table",
                    position: "top",
                    error: true,
                    attachedComponentLayout: {
                        width: myHandLayout.width,
                        height: myHandLayout.height - PLAYER_NAME_HEIGHT,
                        x: myHandLayout.x + gameLayout.x,
                        y: myHandLayout.y + PLAYER_NAME_HEIGHT + gameLayout.y,
                    },
                });
            });
            return;
        }
    }

    pushErrorHandPlayTooltip = (error) => {
        const { gameLayout, layoutHeaderMenu, myHandLayout } = this.state;

        if (gameLayout && layoutHeaderMenu && myHandLayout) {
            this.pushTooltip(null, {
                show: true,
                text: error,
                position: "top",
                error: false,
                attachedComponentLayout: {
                    width: myHandLayout.width,
                    height: myHandLayout.height - PLAYER_NAME_HEIGHT,
                    x: myHandLayout.x + gameLayout.x,
                    y: myHandLayout.y + PLAYER_NAME_HEIGHT + gameLayout.y,
                },
            });
        }
    }

    checkShouterShow = () => {
        const { gameLayout, myHandLayout, player1HandLayout, player2HandLayout, player3HandLayout } = this.state;
        if (gameLayout && myHandLayout && player1HandLayout && player2HandLayout && player3HandLayout) {
            this.showPartyOverShouter();
        }
    }

    showPartyOverShouter = () => {
        if (this.state.partyOverModal.show) return;
        const { G } = this.props;
        const { gameLayout, myHandLayout, player1HandLayout, player2HandLayout, player3HandLayout } = this.state;
        if (!G.party_over) return;

        if (gameLayout && myHandLayout && player1HandLayout && player2HandLayout && player3HandLayout) {
            let msg = "COPY !";
            if (G.hand_to_uncover % 2 === G.shouter % 2) {
                msg = "KANT !";
            }
            this.pushHandShouterTooltip(G.shouter, msg);
        }
    }

    pushHandShouterTooltip = (shouter, msg) => {
        const { gameLayout, myHandLayout, player1HandLayout, player2HandLayout, player3HandLayout } = this.state;
        const { G, playerID } = this.props;
        const myIdx = utils.playerIDtoIdx(G, playerID || "0");

        let position = "top";

        let comp = {
            width: myHandLayout.width,
            height: myHandLayout.height - PLAYER_NAME_HEIGHT,
            x: myHandLayout.x + gameLayout.x,
            y: myHandLayout.y + PLAYER_NAME_HEIGHT + gameLayout.y,
        };
        if (shouter === (myIdx+1)%4) {
            position = "left";
            comp = {
                width: player1HandLayout.width,
                height: player1HandLayout.height - PLAYER_NAME_HEIGHT,
                x: player1HandLayout.x + gameLayout.x,
                y: player1HandLayout.y + PLAYER_NAME_HEIGHT + gameLayout.y,
            };
        }
        else if (shouter === (myIdx+2)%4) {
            position = "bottom";
            comp = {
                width: player2HandLayout.width,
                height: player2HandLayout.height - PLAYER_NAME_HEIGHT,
                x: player2HandLayout.x + gameLayout.x,
                y: player2HandLayout.y + PLAYER_NAME_HEIGHT + gameLayout.y,
            };
        } else if (shouter === (myIdx+3)%4) {
            position = "right";
            comp = {
                width: player3HandLayout.width,
                height: player3HandLayout.height,
                x: player3HandLayout.x + gameLayout.x,
                y: player3HandLayout.y + gameLayout.y,
            };

        }
        let txt = "Gagné !";
        if (G.party_over.winner%2 !== myIdx%2) txt = "Perdu !";

        this.setState({
            pending_hand_card: null,
            partyOverModal: {
                show: true,
                text: txt,
            },
            partyOverShouterModal: {
                show: true,
                text: msg,
                position: position,
                attachedComponentLayout: comp,
            }
        });
    }

    askedForHelp = () => {
        this.setState({
            tooltip: {
                show: true,
                isRulesModal: true,
            }
        })
    }

    showQuitConfirmationModal = () => {
        this.setState({
            tooltip: {
                show: true,
                text: "Voulez-vous vraiment quitter la partie en cours ?",
                fullScreen: false,
                isConfirmationModal: true,
                onConfirm: this.props.onLeave,
            }
        })
    }

    resetLockTimer = (last_ts, diff) => {
        this.last_ts = last_ts;
        if (this.anim) {
            this.anim.stop();
            return;
        }
        if (!diff) diff = (Date.now() - this.last_ts)/1000;
        if (diff >= utils.MOVE_TIMEOUT_S) {
            this.onLock();
            return;
        }
        this.setState({
            displayedTimerValue: 1-diff/utils.MOVE_TIMEOUT_S,
        }, () => {
            this.state.timerValue.setValue(diff/utils.MOVE_TIMEOUT_S);
            this.anim = Animated.timing(this.state.timerValue, {
                toValue: 1,
                duration: (utils.MOVE_TIMEOUT_S-diff)*1000,
                easing: Easing.linear,
            });
            this.anim.start(() => this.onTimerStop());
        });
    }

    onTimerStop = () => {
        this.anim = null;
        let diff = (Date.now() - this.last_ts)/1000;
        if (diff >= utils.MOVE_TIMEOUT_S) {
            this.onLock();
        } else {
            this.resetLockTimer(this.last_ts, diff);
        }
    }

    onPress = (table_card) => {
        const { G, ctx, moves, playerID } = this.props;
        if (ctx.phase !== "play") return;

        const myIdx = utils.playerIDtoIdx(G, playerID || "0");
        const pending_hand_card = this.state.pending_hand_card;

        if (!pending_hand_card) {
            this.pushErrorHandPlayTooltip("Choisissez d'abord une carte à poser");
            return;
        }

        const validate = utils.isValidSwapMove(G, myIdx, pending_hand_card, table_card);
        if (!validate.valid) {
            let error = "Impossible de faire cette action";
            if (validate.reason === utils.INVALID_MOVE_CARD_DOESNT_BELONG_TO_PLAYER) {
                error = "Vous ne pouvez pas jouer cette carte";
            } else if (validate.reason === utils.INVALID_MOVE_CARD_DOESNT_BELONG_TO_TABLE) {
                error = "Cette carte a été retirée de la table";
            }
            this.pushErrorHandPlayTooltip(error);
            return;
        }

        this.setState({
            pending_hand_card: null
        }, () => moves.swap(pending_hand_card, table_card));
    }

    onPressHand = (card) => {
        this.setState({
            pending_hand_card: (this.state.pending_hand_card === card)?null:card,
        });
    }

    onReady = () => {
        const { moves } = this.props;
        moves.ready();
    }

    onContinue = () => {
        this.setState({
            partyOverShouterModal: {
                ...this.state.partyOverShouterModal,
                show: false,
            },
            partyOverModal: {
                ...this.state.partyOverModal,
                show: false,
            },
        }, () => {
            const { moves } = this.props;
            moves.continue();
        });
    }

    onLock = () => {
        const myIdx = utils.playerIDtoIdx(this.props.G, this.props.playerID || "0");
        if (myIdx !== 0) return;
        const { moves, ctx } = this.props;
        if (ctx.phase !== "play") return;
        try {
            moves.lock();
        } catch(err) {
            return;
        }
    }

    onKant = () => {
        const { moves } = this.props;
        moves.kant();
    }

    onCopy = () => {
        const { moves } = this.props;
        moves.copy();
    }

    aiPlay = () => {
        const { ctx, G, playerID, moves } = this.props;
        const playerIdx = utils.playerIDtoIdx(G, playerID);
        if (ctx.phase === "ready") {
            if (!G.ready[playerIdx]) moves.ready();
            return;
        } else if (ctx.phase === "continue") {
            if (!G.continue[playerIdx]) moves.continue();
            return;
        } else if (ctx.phase === "play") {
            const move = ai.getMove(G, ctx, playerIdx);
            if (move && moves[move.name]) moves[move.name](...move.args);
            return;
        }
    }

    render() {
        const { playerID, isAI, players, settings, setSettings, isLocal, username } = this.props;
        const { tooltip, partyOverShouterModal, partyOverModal } = this.state;

        if (isAI) return null;

        const G = this.props.G;
        const ctx = this.props.ctx;

        if (!G) return <Text style={{ color: "white" }}>Chargement...</Text>;
        
        const myIdx = utils.playerIDtoIdx(G, playerID || "0");
        const seat_player_idx = (myIdx, seat) => (myIdx + seat) % 4;
        const playerAtSeat = _.map([0, 1, 2, 3], i => seat_player_idx(myIdx, i));
        let playersNames = _.map(players, (i) => i.name);
        let playerIdxToSeat = [-1, -1, -1, -1];
        _.each(playerAtSeat, (s) => {
            playerIdxToSeat[playerAtSeat[s]] = s;
        });
        if (isLocal) playersNames[playerAtSeat[0]] = username;

        return (
            <View style={{ width: SCREEN_WIDTH, height: SCREEN_HEIGHT, justifyContent: "center", alignItems: "center", position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }} >
                <View onLayout={this.onLayoutHeaderMenu} style={{ width: HEADER_MENU_WIDTH, height: HEADER_MENU_HEIGHT, padding: 2 * PADDING, flexDirection: "row" }} >
                    <Button style={{
                        backgroundColor: 'rgb(168, 155, 120)',
                        borderWidth: 1,
                        width: 40,
                        borderColor: 'white',
                    }} onPress={this.showQuitConfirmationModal} icon="ios-log-out" iconSize={22} />
                    <Button style={{
                        marginLeft: 2 * PADDING,
                        backgroundColor: 'rgb(168, 155, 120)',
                        borderWidth: 1,
                        width: 40,
                        borderColor: 'white',
                    }} onPress={this.askedForHelp} icon="ios-help-circle-outline" iconSize={22} />
                    <Button style={{
                        marginLeft: 2 * PADDING,
                        backgroundColor: 'rgb(168, 155, 120)',
                        borderWidth: 1,
                        width: 40,
                        borderColor: 'white',
                    }} onPress={this.props.showSettingsModal} icon="ios-cog" iconSize={22} />
                    <View style={{ flex: 1 }} />
                    <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
                        <Text style={{ color: "white" }}>{"score:   "}</Text>
                        <AnimateNumber style={{ color: "white" }} value={G.scores[myIdx % 2]} />
                        <Text style={{ color: "white" }}>{" - "}</Text>
                        <AnimateNumber style={{ color: "white" }} value={G.scores[(myIdx + 1) % 2]} />
                    </View>
                </View>
                <View onLayout={this.onGameLayout}>
                    <View onLayout={this.onPlayer2HandLayout} style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", zIndex: 100 }}>
                        <Hand settings={settings}
                            phase={ctx.phase}
                            isReady={G.ready[playerAtSeat[2]]}
                            isContinue={G.continue[playerAtSeat[2]]}
                            playerName={playersNames[playerAtSeat[2]] || `Joueur ${playerAtSeat[2]}`}
                            cards={G.hands[playerAtSeat[2]]}
                            seat={2} />
                    </View>
                    <View onLayout={this.onTableContainerLayout} style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        <View onLayout={this.onPlayer3HandLayout} style={{ justifyContent: "center", alignItems: "center", zIndex: 100 }}>
                            <Hand settings={settings} 
                                phase={ctx.phase}
                                isReady={G.ready[playerAtSeat[3]]}
                                isContinue={G.continue[playerAtSeat[3]]}
                                playerName={playersNames[playerAtSeat[3]] || `Joueur ${playerAtSeat[3]}`}
                                cards={G.hands[playerAtSeat[3]]}
                                seat={3} />
                        </View>
                        <View onLayout={this.onTableLayout} style={{ justifyContent: "center", alignItems: "center", width: TABLE_WIDTH, height: TABLE_HEIGHT }} >
                            <ImageBackground source={IMGS_SRC['back']}
                                style={{
                                    width: TABLE_WIDTH,
                                    height: TABLE_HEIGHT
                                }} >
                                <Table settings={settings}
                                    onAllCardsLayout={this.onAllCardsLayout}
                                    onReady={this.onReady}
                                    onPress={this.onPress}
                                    onKant={this.onKant}
                                    onCopy={this.onCopy}
                                    displayedTimerValue={this.state.displayedTimerValue}
                                    gameOver={G.party_over}
                                    ImReady={G.ready[playerAtSeat[0]]}
                                    myIdx={myIdx}
                                    phase={ctx.phase}
                                    table={G.table}
                                    mySecret={G.secrets[myIdx % 2]} />
                            </ImageBackground>
                        </View>
                        <View onLayout={this.onPlayer1HandLayout} style={{ justifyContent: "center", alignItems: "center", zIndex: 100 }}>
                            <Hand settings={settings}
                                phase={ctx.phase}
                                isReady={G.ready[playerAtSeat[1]]}
                                isContinue={G.continue[playerAtSeat[1]]}
                                playerName={playersNames[playerAtSeat[1]] || `Joueur ${playerAtSeat[1]}`}
                                cards={G.hands[playerAtSeat[1]]}
                                seat={1} />
                        </View>
                    </View>
                    <View onLayout={this.onMyHandLayout} style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", zIndex: 100 }}>
                        <Hand settings={settings}
                            onPress={this.onPressHand}
                            selected={this.state.pending_hand_card}
                            phase={ctx.phase}
                            isReady={G.ready[playerAtSeat[0]]}
                            isContinue={G.continue[playerAtSeat[0]]}
                            playerName={playersNames[playerAtSeat[0]] || `Joueur ${playerAtSeat[0]}`}
                            cards={G.hands[playerAtSeat[0]]}
                            seat={0} />
                    </View>
                </View>
                <HelpTooltipDisplay
                    transparent={tooltip.transparent}
                    zIndex={999}
                    show={tooltip.show}
                    text={tooltip.text}
                    error={tooltip.error}
                    onHide={this.onHideTooltip}
                    position={tooltip.position}
                    isConfirmationModal={tooltip.isConfirmationModal}
                    onConfirm={tooltip.onConfirm}
                    isRulesModal={tooltip.isRulesModal}
                    settings={settings}
                    setSettings={setSettings}
                    attachedComponentLayout={tooltip.attachedComponentLayout} />
                
                <HelpTooltipDisplay
                    transparent={true}
                    zIndex={990}
                    show={partyOverShouterModal.show}
                    text={partyOverShouterModal.text}
                    position={partyOverShouterModal.position}
                    attachedComponentLayout={partyOverShouterModal.attachedComponentLayout} />

                <HelpTooltipDisplay
                    transparent={true}
                    zIndex={995}
                    show={partyOverModal.show}
                    text={partyOverModal.text}
                    buttonTitle="Rejouer"
                    buttonColor="#0B6E4F"
                    isConfirmationModal={true}
                    onConfirm={this.onContinue} />
            </View>
        );
    }
}


export default Game; 